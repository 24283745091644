import React from "react";
import './button-row.styles.scss';
import BUTTON_ROW_DATA from "./button-row.data";
import ButtonColComponent from "../button-component/button-col.component";

class ButtonRow extends React.Component {
    info = this.props.info;
    render() {
        return (
            <>
                <div className={"row mx-5 mt-2 px-0"}>
                    <ButtonColComponent data={this.info.row1}  />
                </div>
                <div className={"row mx-2 mt-2 px-0"}>
                    <ButtonColComponent data={this.info.row2}  />
                </div>
                {this.info.row3 ? (
                    <div className={"row mx-5 mt-2 px-5"}>
                        <ButtonColComponent data={this.info.row3}  />
                    </div>
                ) : null}

            </>

        )
    }
}

export default ButtonRow