import React from 'react';
import TOOLKIT_DATA from "./location.data";
import {Helmet} from "react-helmet";
import HeaderHero from "../../components/header-hero/header-hero.component";
import CardsRow from "../../components/cards-row/cards-row.component";
import InfoCardsRow from "../../components/info-cards-row/info-cards-row.component";
import HighlightsWithTitle from "../../components/highlight-with-title/highlights-with-title.component";
import MapFullComponent from "../../components/map-row/map-full.component";
import LOCATION_DATA from "./location.data";
import BreadcrumbTop from "../../components/breadcrumb-top/breadcrumb-top.component";

class LocationPage extends React.Component {
    state = LOCATION_DATA;
    render() {
        const {highlightTitle, page, breadcrumb} = this.state
        return(
            <>
                <main className={"page-body"}>
                    <Helmet>
                        <title>{page.title}</title>
                    </Helmet>

                    <div className={"container-fluid"}>
                        <BreadcrumbTop info={breadcrumb}/>
                        <MapFullComponent />
                        <HighlightsWithTitle info={highlightTitle}/>
                    </div>
                </main>
            </>
        );
    }
}

export default LocationPage;
