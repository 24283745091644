const LOCATIONS_TABLE_DATA = {
    cells: [
        {
            id: "1",
            locationName: "Los Alamitos Medical Center",
            locationUrl: "https://www.losalamitosmedctr.com/",
            locationAddress: "3751 Katella Ave.",
            locationCity:  "Los Alamitos, CA",
            locationZip: "90720",
            locationPhone: "1-562-598-1311",
            operatingDays: ["Monday–Friday", "Saturday–Sunday", "Holidays"],
            operatingHours: ["Emergency room", "open 24 hours"],
        },
        {
            id: "2",
            locationName: "MemorialCare Long Beach Medical Center",
            locationUrl: "https://www.memorialcare.org/locations/long-beach-medical-center",
            locationAddress: "2801 Atlantic Ave.",
            locationCity:  "Long Beach, CA",
            locationZip: "90806",
            locationPhone: "1-562-933-2000",
            operatingDays: ["Monday–Friday", "Saturday–Sunday", "Holidays"],
            operatingHours: ["Emergency room", "open 24 hours"],
        },

    ]
}

export default LOCATIONS_TABLE_DATA