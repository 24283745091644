import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import FormComponent from "../form/form.component";
import './modal-video.styles.scss';
import ModalVideo from "./modal-video-component.component";

function ModalVideoComponent() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <a onClick={handleShow} className={"btn btn-light text-nowrap"}>
                    <span className={"font-body-2 font-white"}><b>View virtual tour</b></span>
            </a>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ModalVideo />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalVideoComponent