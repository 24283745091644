import React from 'react';

class TextFullRow extends React.Component{
    render() {
        return(
            <div className={"row justify-content-center align-items-center mb-5"}>
                <div className={"col-8 text-center"}>
                    <h1 className={"pb-2 pt-3"}>Urgent care vs. the emergency room</h1>
                    <p className={"mb-4 px-5"}>
                        <h6 className={"font-body-1"}>
                            How do you know when to choose an urgent care center over the emergency room? We’ve created a guide to help you understand where to go when you need immediate care. Knowing your options can help you access the right care while avoiding any unneeded tests or costs.
                        </h6>
                    </p>
                </div>
            </div>

        )
    }
}

export default TextFullRow