import React from 'react'
import MapFrameComponent from "./map-frame/map-frame.component";

class MapRowComponent extends React.Component {
    render(){
        return(
            <div className={"row align-items-center px-3"}>
                <div className={"col-lg-6 pl-3"}>
                    <h3>Lorem ipsum dolor sit amet</h3>
                    <h1>Location and HOO</h1>
                    <h4>lorem ipsum dolor sit amet</h4>
                </div>
                <div className={"col-lg-6 p-0"}>
                    <MapFrameComponent />
                </div>
            </div>
        )
    }
}

export default MapRowComponent






