import React from "react";
import {Accordion} from "react-bootstrap";
import FAQ_DROPDOWN_DATA from "./faq-dropdown.data";
import FaqDropdownRowComponent from "./faq-dropdown-row.component";
import FaqDropdownCol from "./faq-dropdown-col.component";

class FaqDropdown extends React.Component {
    state = FAQ_DROPDOWN_DATA;
    render() {
        return(
            <div className={"row my-5"}>
                <div className={"col-12 text-center mb-2"}>
                    <h1>{this.state.title}</h1>
                </div>
                <div className={"col-12"}>
                   <FaqDropdownCol data={this.state} />
                </div>
            </div>
        )
    }
}

export default FaqDropdown