import React from 'react';
import HighlightSingle from "../highlights/highlights-single.component";

class HighlightsRowComponent extends React.Component{
    render() {
        return (
            <div className={"row align-items-center my-5"}>
                <HighlightSingle isLeft={this.props.isLeft} info={this.props.info}/>
            </div>
        )
    }
}

export default HighlightsRowComponent