import imageUrl1 from "../../img/placeholders/cardplaceholder1.png";
import imageUrl2 from "../../img/placeholders/cardplaceholder2.png";
import imageUrl3 from "../../img/placeholders/cardplaceholder3.png";
import imageUrl4 from "../../img/placeholders/cardplaceholder1.png";
import imageUrl5 from "../../img/placeholders/cardplaceholder2.png";
import imageUrl6 from "../../img/placeholders/cardplaceholder3.png";
import heroImage from "../../img/placeholders/hero-example.png";
import searchImage from "../../img/search_placeholder.png";


const PATIENT_RESOURCES_DATA = {
    breadcrumb: {
        items: [
            {
                id: 1,
                linkUrl: "/patient-resources",
                title: "Patient resources",
            }
        ]
    },
    page: {
      title: "Optum HCC - Patient resources",
    },
    cardsRow1: {
        title: "High-demand topics",
        cards: [
            {
                id: 1,
                title: "Lorem Ipsum",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut purus sit amet tortor rutrum rutrum sit amet nec leo. In eget ornare tellus. Quisque lobortis auctor blandit. Maecenas sed ex neque.",
                linkText: "Read more",
                linkUrl: "/#",
                image: imageUrl1,
                inRowSize: "4",
            },
            {
                id: 2,
                title: "Lorem Ipsum",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut purus sit amet tortor rutrum rutrum sit amet nec leo. In eget ornare tellus. Quisque lobortis auctor blandit. Maecenas sed ex neque.",
                linkText: "Read more",
                linkUrl: "/#",
                image: imageUrl2,
                inRowSize: "4",
            },
            {
                id: 3,
                title: "Lorem Ipsum",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut purus sit amet tortor rutrum rutrum sit amet nec leo. In eget ornare tellus. Quisque lobortis auctor blandit. Maecenas sed ex neque.",
                linkText: "Read more",
                linkUrl: "/#",
                image: imageUrl3,
                inRowSize: "4",
            },
        ],
    },
    hero: {
        id: 1,
        title: 'Patient resources',
        text:'We’re honored to care for our Leisure World community',
        backgroundImg: heroImage,
        search: searchImage,
    },
    faqData: {
        title: 'Frequently asked questions',
        faqs: [
            {
                id: 1,
                faqTitle: 'Are you an insurance company?',
                faqText: 'No. The HCC provides direct care to patients at our clinic location. Optum Care Network–Monarch is a health care services network. Through the network, you can access hospitals, urgent care centers and labs. Your insurance plan handles your benefits and copays. Optum works with your insurance to take care of authorizations, referrals, billing and claims.',
            },
            {
                id: 2,
                faqTitle: 'Are specialists available at the HCC?',
                faqText: 'Yes. We employ several specialists at the clinic and also work with leading specialists in the area to provide care to our patients. To find a specialist, you can talk to your primary care provider or call Optum directly. Please let customer service know that you’re an HCC patient. You can call Monday through Friday, 8:30 a.m. to 5:00 p.m. Holiday hours may vary. During after hours, please leave a message. They’ll likely return your call the next business day. The customer service numbers are listed below:',

            },
            {
                id: 3,
                faqTitle: 'Who helps with specialists and authorizations?',
                faqText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
            {
                id: 4,
                faqTitle: 'What insurances are accepted?',
                faqText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
        ],
    },
    faqData2: {
        title: 'Downloads',
        faqs: [
            {
                id: 1,
                faqTitle: 'PDF name 1',
            },
            {
                id: 2,
                faqTitle: 'PDF name 1',
            },
            {
                id: 3,
                faqTitle: 'PDF name 3',
            },
            {
                id: 4,
                faqTitle: 'PDF name 4',
            },
        ],
    }
}

export default PATIENT_RESOURCES_DATA;
