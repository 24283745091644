import React from 'react'
import MapFrameComponent from "./map-frame/map-frame.component";

class MapFullComponent extends React.Component {
    render(){
        return(
            <div className={"row align-items-center px-0"}>
                <div className={"col-lg-12 p-0"}>
                    <MapFrameComponent />
                </div>
            </div>
        )
    }
}

export default MapFullComponent






