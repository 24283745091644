import imageUrl from '../../img/placeholdercard.png';

const CARDS_DATA = {
    cards: [
        {
            id: 1,
            title: "title lorem",
            text: "some text lorem ipsum",
            image: imageUrl,
            inRowSize: "4",
        },
        {
            id: 2,
            title: "title lorem 2",
            text: "some text lorem ipsum",
            image: imageUrl,
            inRowSize: "4",
        },
        {
            id: 3,
            title: "title lorem 3",
            text: "some text lorem ipsum",
            image: imageUrl,
            inRowSize: "4",
        },
    ]
}

export default CARDS_DATA