const FAQ_DROPDOWN_DATA = {
    title: 'Frequently asked questions',
    faqs: [
        {
            id: 1,
            faqTitle: 'Lorem Ipsum Dolor sit Amet?',
            faqText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
        {
            id: 2,
            faqTitle: 'Lorem Ipsum Dolor sit Amet?',
            faqText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
        {
            id: 3,
            faqTitle: 'Lorem Ipsum Dolor sit Amet?',
            faqText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
    ],
}

export default FAQ_DROPDOWN_DATA