import React from 'react';
import TOOLKIT_DATA from "./toolkit.data";
import './toolkit.styles.scss'
import {Helmet} from "react-helmet";
import ButtonRow from "../../components/button-row/button-row.component";
import HeaderHero from "../../components/header-hero/header-hero.component";
import CardsRow from "../../components/cards-row/cards-row.component";
import MapRowComponent from "../../components/map-row/map-row.component";
import HighlightsWithTitleComponent from "../../components/highlight-with-title/highlights-with-title.component";
import FooterAuxComponent from "../../components/footer/footer-aux/footer-aux.component";
import HeaderVideo from "../../components/header-video/header-video.component";
import NavigationComponent from "../../components/navigation/navigation.component";
import InfoCardsRow from "../../components/info-cards-row/info-cards-row.component";
import HighlightsWithTitle from "../../components/highlight-with-title/highlights-with-title.component";
import HighlightsRowComponent from "../../components/highlights/highlights-row.component";
import FooterBottom from "../../components/footer/footer-bottom.component";
import FullInfoRow from "../../components/full-info-row/full-info-row.component";
import ProvidersHighlight from "../../components/providers-highlight/providers-highlight.component";
import MapFullComponent from "../../components/map-row/map-full.component";
import FAQRow from "../../components/faq-row/faq-row.component";
import FAQRowFull from "../../components/faq-row/faq-row-full.component";
import LocationsTable from "../../components/locations-table/urgent-care/locations-table.component";
import TextFullRow from "../../components/text-full-row/text-row-full.component";
import FaqDropdown from "../../components/faq-dropdown/faq-dropdown-row.component";

class Toolkit extends React.Component {
    state = TOOLKIT_DATA;
    render() {
        return(
            <>
                <main className={"page-body"}>
                    <Helmet>
                        <title>Toolkit Home</title>
                    </Helmet>

                    <div className={"container-fluid"}>
                        <NavigationComponent/>
                        <HeaderHero />
                        <HeaderVideo />
                        <ButtonRow data={this.state.buttons} />
                        <CardsRow />
                        <MapRowComponent />
                        <HighlightsRowComponent isLeft={false} />
                        <HighlightsRowComponent isLeft={true} />
                        <InfoCardsRow />
                        <HighlightsWithTitle />
                        <FooterAuxComponent />
                        <FooterBottom />
                        <ButtonRow data={this.state.buttonsLight} />
                        <FullInfoRow />
                        <ProvidersHighlight />
                        <MapFullComponent />
                        <FAQRowFull />
                        <FAQRow />
                        <LocationsTable />
                        <TextFullRow />
                        <FaqDropdown />
                    </div>
                </main>
            </>
        );
    }
}

export default Toolkit;
