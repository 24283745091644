import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ButtonComponent from "../button-component/button/button.component";
import ButtonDownloadComponent from "../button-component/button/button-download.component";

const HighlightSingle = ({isLeft, buttonText, info}) => {

        return (
            <>
                <div className={"col-lg-12 mb-5"}>
                    <div className={"row align-items-center px-0 px-md-5"}>
                        { isLeft ? (<div style={{ backgroundImage: `url(${info.image})`, backgroundSize: "cover", backgroundPosition: "center", height:"657px"}} className={"col-md-6 col-12 bg-navyblue"}></div>) : false }
                        <div className={"col-md-6 col-12 pr-5"}>
                            <div className={"col-12 py-2"}>
                                {info.icon ? <img src={info.icon}/> : null}
                            </div>
                            <div className={"col-12 py-2"}>
                                <span className={"font-body-2 font-navy-blue"}><b>{info.subtitle}</b></span>
                            </div>
                            <div className={"col-12 py-2"}>
                                <h1>{info.title}</h1>
                            </div>
                            {info.locationPatch ? (
                                <>
                                    <div className={"col-12 py-2"}>
                                        <h6 className={"font-body-1"}><b>Optum Health Care Center</b></h6>
                                        <h6 className={"font-body-1"}>1661 Golden Rain Road</h6>
                                        <h6 className={"font-body-1"}>Seal Beach, CA 90740</h6>
                                        <br />
                                        <h6 className={"font-body-1"}><b>Phone:</b></h6>
                                        <h6 className={"font-body-1"}> 1-562-493-9581</h6>
                                        <br />
                                        <h6 className={"font-body-1"}><b> Hours:</b></h6>
                                        <h6 className={"font-body-1"}> Monday–Friday, 8 a.m.–5 p.m.</h6>
                                    </div>
                                </>
                            ) : null}
                            <div className={"col-12 py-2 pr-0 pr-md-5"} style={{paddingRight: "100px"}}>
                                {info.textBoldPatch ? (
                                    <>
                                        <span className={'font-body-1'}>{info.text}</span>
                                        <span className={'font-body-1'}><b>{info.textBoldPatch}</b></span>
                                        <span className={'font-body-1'}>{info.textPatch}</span>
                                    </>


                                ) : (<span className='font-body-1 text-wrap'>{info.text}</span>)}

                                {info.textLabPatch ? (
                                    <span className={'font-body-1'}>
                                        Our on-site lab is meant to make it easier for you to get lab work done.
                                        All you need is a referral from your primary care provider.
                                        Call <a href={"tel:+15624939581"}>1-562-493-9581</a>, TTY <b>711</b>, Monday–Friday, 6 a.m.–2 p.m.
                                    </span>
                                ) : null}

                            </div>
                            <div className={"col-12 py-2"}>
                                {info.list ? (<>
                                    <ul className='pr-5'>
                                        {info.list.map(item => {
                                            return (
                                                <>
                                                    <li className='py-3 font-body-1'> {item.text}</li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </>) : null }
                            </div>
                            <div className={"col-12 py-2"}>
                                {info.buttonText ? (<ButtonComponent buttonLink={info.buttonLink} title={info.buttonText} buttonLight={true}/>) : null}
                                {info.downloadText ? (<ButtonDownloadComponent buttonLink={info.downloadLink} title={info.downloadText} buttonLight={true}/>) : null}
                            </div>
                            <div className={"col-12 py-2"}>
                                {info.linkText ? (<a href={info.linkUrl}><h5 className={"font-blue"}>{info.linkText}</h5></a>) : null}
                            </div>
                        </div>
                        { !isLeft ? (<div style={{ backgroundImage: `url(${info.image})`, backgroundSize: "cover", backgroundPosition: "center", height:"657px"}} className={"col-md-6 col-12 bg-navyblue"}></div>) : false }
                    </div>
                </div>

            </>
        )
    }

export default HighlightSingle