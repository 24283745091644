import React from 'react';
import ButtonSmallComponent from "../button-component/button/button-small.component";

class FAQRowFull extends React.Component{
    render() {
        return(
            <div className={"row align-items-center bg-orange py-5"}>
                <div className={"col-12 mt-3 text-center"}>
                    <h3>Lorem ipsum dolor sit amet</h3>
                    <h1>Lorem ipsum dolor sit amet</h1>
                    <h3>Lorem ipsum dolor sit amet</h3>
                </div>
                <div className={"col-12 text-center mt-5"}>
                    <ButtonSmallComponent buttonWhite={true} title={"Learn More"}/>
                </div>
            </div>

        )
    }
}

export default FAQRowFull