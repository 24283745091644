import React from "react";
import "./header-video.styles.scss"
import VideoComponentComponent from "./video-component.component";
import HEADER_HERO_DATA from "../header-hero/header-hero.data";
import ButtonComponent from "../button-component/button/button.component";
import ModalVideoComponent from "../modal-video/modal-video.component";

class HeaderVideo extends React.Component {
    state = HEADER_HERO_DATA;
    info = this.props.info;
    render() {
        return (<div className={"row hero-div-vid"}>
                <div className={"hero-div-vid hero-wrapper"} style={{
                    top: "0", left: "0",
                    width: "100%",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    overflow: "hidden",
                }}>
                    <VideoComponentComponent />
                    
                </div>
                <div className={"row w-100 hero-div align-items-center mb-5 d-none d-md-flex"} style={{position: "absolute"}}>

                    <div className={"col-12 col-md-6 pl-5 pl-md-5"} style={{paddingLeft: "100px"}} >
                        <h1 className={"font-white"}>{this.props.info.title}</h1>
                        <span className={"font-body-1 font-white"}><b>{this.props.info.text}</b></span>
                        <ModalVideoComponent />
                    </div>
                    <div className={"col-md-6"}>

                    </div>

                </div>
                <div className={"row w-100 align-items-center mb-5"} style={{position: "relative"}}>
                    <div className={"col-12 d-md-none pl-5 pt-5"} >
                        {console.log(this.props.info)}
                        <h2 className={"font-navy-blue"}>{this.props.info.title}</h2>
                        <h3 className={"font-body-1"}>{this.props.info.text}</h3>
                        <ModalVideoComponent />
                    </div>
                </div>


            </div>


        )
    }
}

export default HeaderVideo