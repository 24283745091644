import React from "react";
import virtualTour from '../../img/virtual-tour-hero.mp4';

export default function VideoComponentComponent() {
    return (
            <video loop muted autoPlay={true} width={"100%"}>
                <source
                    src={virtualTour}
                    type="video/mp4"
                />
            </video>
    );
}