const LOCATIONS_TABLE_DATA = {
    cells: [
        {
            id: "4",
            locationName: "Optum–Seal Beach Family",
            locationUrl: "https://www.optumcare.com/ocnc/facilities/ca/seal-beach/1198-pacific-coast-hwy-optum-seal-beach-family-urgent-care-ste-i-urgent-care-55f21825-560c-4d7f-a826-f6d300ac589f-1198pacificcoasthwy",
            locationAddress: "1198 Pacific Coast Hwy. Ste. I",
            locationCity:  "Seal Beach, CA",
            locationZip: "90740",
            locationPhone: "1-562-598-2904",
            operatingDays: ["Monday–Friday", "Saturday–Sunday", "Holidays"],
            operatingHours: ["5:00 p.m.–9:30 p.m.", "10:00 a.m.–5:30 p.m.", "10:00 a.m.–5:30 p.m"],

        },
        {
            id: "3",
            locationName: "MedPost Urgent Care",
            locationUrl: "#",
            locationAddress: "3851 Katella Ave.",
            locationCity:  "Los Alamitos, CA",
            locationZip: "90720",
            locationPhone: "1-562-521-1231",
            operatingDays: ["Monday–Sunday"],
            operatingHours: ["8:00 a.m.–8:00 p.m."],
        },
    ]
}

export default LOCATIONS_TABLE_DATA