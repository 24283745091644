import React from 'react';
//import {render} from "@testing-library/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./button.styles.scss";

const ButtonComponent = ( {id, buttonCopy, buttonLink, className, icon, hasSecondLine, buttonCopy2 } ) => {
    return (
        <>
            <a key={id} className={`${className ? className : "btn" } no-underline btn-spacing px-5"`}
             href={`${buttonLink}`} target="_self">
                {buttonCopy}
                { hasSecondLine && window.innerWidth > 525 ? <br/> : "" }
                { hasSecondLine && window.innerWidth > 525 ? buttonCopy2 : "" }
                <FontAwesomeIcon className="ml-1" icon={icon} />
            </a>
        </>
    )
}

export default ButtonComponent;
