import React from "react";
import FULL_INFO_DATA from "./full-info-row.data";
import FullInfoComponent from "./full-info.component";

class FullInfoRow extends React.Component {
    info = this.props.info;
    render() {
        return (
            <>
                <div className={"row bg-beige"}>
                    { this.info.map( info => {
                        return (
                        <FullInfoComponent title={info.title} text={info.text} id={info.id} />
                        )})
                    }
                </div>
            </>
        )
    }
}

export default FullInfoRow