import SullyvanTang from '../../img/providers/SULLYVAN-TANG.jpg';
import DavidPark from '../../img/providers/David-Park.jpg';
import RudolfHaider from '../../img/providers/RUDOLF-HAIDER.jpg';
import NancySmith from '../../img/providers/Nancy-Smith.jpg';
import RichardLee from '../../img/providers/RICHARD-LEE.jpg';
import SophiaChun from '../../img/providers/SOPHIA-CHUN.jpg';
import heroImage from "../../img/hcc/providers-hero.png";
import EllenDayon from "../../img/providers/Ellen-Dayon.jpg";
import SteveBecker from "../../img/providers/Steve-Becker.jpg";
import ChrisNguyen from "../../img/providers/Chris-Nguyen.jpg";
import searchImage from "../../img/search_placeholder.png";

const PROVIDERS_DATA = {
    breadcrumb: {
        items: [
            {
                id: 1,
                linkUrl: "/providers",
                title: "Providers",
            }
        ]
    },
    sections: [
        {
            id: 1,
            imageUrl: SophiaChun,
            providerInteriorImage: SophiaChun,
            sex: "Female",
            providerLocations: [1],
            linkUrl: 'sophia-chun',
            providerName: "Sophia Chun",
            providerTitle: "MD",
            boardCertified: true,
            specialty: ["HCC"],
            providerSpecialty: ["Internal medicine,", "Physical medicine,", "Rehabilitation"],
            phone: "1-562-493-9581",
            languages: ["English", "Korean"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Loma Linda University'
                },
                {
                    type: 'Residency',
                    name: 'Loma Linda University'
                },
                {
                    type: 'Internship',
                    name: 'Loma Linda University'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
        },
        {
            id: 2,
            imageUrl: EllenDayon,
            providerInteriorImage: EllenDayon,
            sex: "Female",
            providerLocations: [1],
            linkUrl: 'ellen-dayon',
            providerName: "Ellen Dayon",
            providerTitle: "MD",
            boardCertified: true,
            specialty: ["HCC"],
            providerSpecialty: ["Internal medicine,"],
            phone: "1-562-493-9581",
            languages: ["English", "Russian"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'State Institute of Advanced Medical Training, St. Petersburg'
                },
                {
                    type: 'Residency',
                    name: 'University of Southern California'
                },
                {
                    type: 'Internship',
                    name: 'University of Southern California'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "I wanted to be a doctor for as long as I can remember. It’s a great opportunity to help people and develop long-term relationships with patients and their families. Many times there are difficult treatment decisions that need to be made. By working with my patients, I’m able to provide a care plan that’s best for them for long lasting health."
        },
        {
            id: 3,
            imageUrl: RudolfHaider,
            providerInteriorImage: RudolfHaider,
            sex: "Male",
            providerLocations: [1],
            linkUrl: 'rudolf-haider',
            providerName: "Rudolf Haider",
            providerTitle: "MD",
            boardCertified: true,
            specialty: ["HCC"],
            providerSpecialty: ["Family medicine"],
            phone: "1-562-493-9581",
            languages: ["English", "German", "Spanish"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University Autónoma de Guadalajara'
                },
                {
                    type: 'Residency',
                    name: 'University of Iowa Hospitals and Clinics'
                },
                {
                    type: 'Internship',
                    name: 'University of Iowa Hospitals and Clinics'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
        },
        {
            id: 4,
            imageUrl: DavidPark,
            providerInteriorImage: DavidPark,
            sex: "Male",
            providerLocations: [1],
            linkUrl: 'david-park',
            providerName: "David R. Park",
            providerTitle: "MD",
            boardCertified: true,
            specialty: ["HCC"],
            providerSpecialty: ["Family medicine"],
            phone: "1-562-493-9581",
            languages: ["English", "Korean"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Illinois College of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Hinsdale Hospital Family Practice'
                },
                {
                    type: 'Internship',
                    name: 'Hinsdale Hospital Family Practice'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
        },
        {
            id: 5,
            imageUrl: NancySmith,
            providerInteriorImage: NancySmith,
            sex: "Female",
            providerLocations: [1],
            linkUrl: 'nancy-smith',
            providerName: "Nancy Smith",
            providerTitle: "MD",
            boardCertified: true,
            specialty: ["HCC"],
            providerSpecialty: ["Family medicine"],
            phone: "1-562-493-9581",
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Illinois Urbana-Champaign'
                },
                {
                    type: 'Residency',
                    name: 'Presbyterian Intercommunity Hospital'
                },
                {
                    type: 'Internship',
                    name: 'Presbyterian Intercommunity Hospital'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
        },
        {
            id: 6,
            imageUrl: SullyvanTang,
            providerInteriorImage: SullyvanTang,
            sex: "Male",
            providerLocations: [1],
            linkUrl: 'sullyvan-tang',
            providerName: "Sullyvan Tang",
            providerTitle: "MD",
            boardCertified: true,
            specialty: ["HCC"],
            providerSpecialty: ["Internal medicine"],
            phone: "1-562-493-9581",
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'SUNY Health Science Center at Brooklyn'
                },
                {
                    type: 'Residency',
                    name: 'University of California, Irvine'
                },
                {
                    type: 'Internship',
                    name: 'University of California, Irvine'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
        },
        {
            id: 7,
            imageUrl: SteveBecker,
            providerInteriorImage: SteveBecker,
            sex: "Male",
            providerLocations: [2],
            linkUrl: 'steve-becker',
            providerName: "Steve Becker",
            providerTitle: "DO",
            boardCertified: true,
            specialty: ["Seal Beach"],
            providerSpecialty: ["Internal medicine"],
            phone: "1-562-493-9581",
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ohio University College of Osteopathic Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Pacific Hospital Of Long Beach'
                },
                {
                    type: 'Internship',
                    name: 'Pacific Hospital Of Long Beach'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
        },
        {
            id: 8,
            imageUrl: ChrisNguyen,
            providerInteriorImage: ChrisNguyen,
            sex: "Male",
            providerLocations: [2],
            linkUrl: 'chris-nguyen',
            providerName: "Christopher Nguyen",
            providerTitle: "MD",
            boardCertified: true,
            specialty: ["Seal Beach"],
            providerSpecialty: ["Internal medicine"],
            phone: "1-562-493-9581",
            languages: ["English", "Vietnamese"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Temple University'
                },
                {
                    type: 'Residency',
                    name: 'LAC+USC Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'LAC+USC Medical Center'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
        },

     /*   {
            id: 3,
            imageUrl: RichardLee,
            providerInteriorImage: RichardLee,
            sex: "Male",
            providerLocations: [1],
            linkUrl: 'richard-lee',
            providerName: "Richard Han Lee",
            providerTitle: "DO",
            boardCertified: true,
            specialty: ["HCC"],
            providerSpecialty: ["Family medicine"],
            phone: "1-562-493-9581",
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ohio University College of Osteopathic Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Long Beach Memorial Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Long Beach Memorial Medical Center'
                },
            ],
            digital: "Yes",
            location: "Optum Health Care Center",
            location2: " ",
            region: ["Seal Beach"],
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            npi: "",
            telehealth: "",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "",
        },*/



    ],


    buttons: [
        {
            id: 1,
            buttonCopy: "San Gabriel Valley",
            buttonLink: "#san-gabriel-valley",
        },
        {
            id: 2,
            buttonCopy: "San Fernando Valley",
            buttonLink: "#san-fernando-valley"
        },
        {
            id: 3,
            buttonCopy: "South Bay",
            buttonLink: "#south-bay"
        },
        {
            id: 4,
            buttonCopy: "LA/Downtown",
            buttonLink: "#la-downtown"
        },
        {
            id: 5,
            buttonCopy: "South OC",
            buttonLink: "#south-oc"
        },
        {
            id: 6,
            buttonCopy: "North OC",
            buttonLink: "#north-oc"
        },
        {
            id: 7,
            buttonCopy: "Long Beach",
            buttonLink: "#long-beach"
        },
    ],
    hero: {
        id: 1,
        title: 'Our providers',
        text:'Your doctor provides care that’s personalized just for you.' +
            '',
        backgroundImg: heroImage,
        search: searchImage,
    },
}

export default PROVIDERS_DATA;

