import React from 'react';
import './button.styles.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const ButtonSmallComponent = ({title, buttonWhite}) => {
    return (
        <a className={buttonWhite ? "btn btn-white" : "btn optum-btn"}>
                <div className={"row"}>
                    <div className={"col-12 justify-content-center"}>
                        <h3>{title}</h3>
                    </div>
                </div>
        </a>
    )
}

export default ButtonSmallComponent