import React from 'react';
import ProviderGallery from "../../components/provider-gallery/provider-gallery.component";
import {Route} from "react-router";
import './providers-gallery.styles.scss';
import PROVIDERS_DATA from './provider.data'
import heroImage from '../../img/placeholders/hero-example.png'
import {Helmet} from "react-helmet";
import ButtonsDirectoryComponent from "../../components/buttons-directory/buttons-directory.component";
import {hideMask} from "../../components/navigation/navigation.component";
import HeaderHero from "../../components/header-hero/header-hero.component";
import BreadcrumbTop from "../../components/breadcrumb-top/breadcrumb-top.component";
const info = PROVIDERS_DATA;

const ProvidersPage = ( {match} ) => (
    <>
            <Helmet>
                <title>Optum HCC- Providers</title>
            </Helmet>
            <main className='page-body' onClick={hideMask}>
                <BreadcrumbTop info={info.breadcrumb}/>
                <HeaderHero info={info.hero}/>
                {/* <ButtonsDirectoryComponent buttons={PROVIDERS_DATA.buttons}/> */}
                <Route exact path={`${match.path}`} component={ProviderGallery}/>
            </main>
    </>
)

export default ProvidersPage;
