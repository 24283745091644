const FOOTER_AUX_DATA = {
    section1: {
        title: 'Types of care',
        items: [
            {
                title: 'Primary care',
                link: 'https://www.optumcare.com/state-networks/locations/nevada/ocnv/types-of-care/primary-care.html',
                newWindow: false,
                id: 1
            },
            {
                title: 'Specialty and surgical care',
                link: 'https://www.optumcare.com/state-networks/locations/nevada/ocnv/types-of-care/specialty-care.html',
                newWindow: false,
                id: 2
            },
            {
                title: 'Senior care',
                link: 'https://www.optumcare.com/state-networks/locations/nevada/ocnv/types-of-care/senior-care.html',
                newWindow: false,
                id: 3
            }
        ]
    },
    section2: {
        title: 'Prepare for your visit',
        items: [
            {
                title: 'Become a patient',
                link: 'https://www.optumcare.com/state-networks/locations/nevada/ocnv/patient-information/become-patient.html',
                newWindow: false,
                id: 1
            },
     
            {
                title: 'Patient portal',
                link: 'https://www.optumcare.com/state-networks/locations/nevada/ocnv/patient-information/patient-online-services.html',
                newWindow: false,
                id: 2
            }
        ]
    },
    section3: {
        title: 'About Us',
        items: [
            {
                title: 'Who we are',
                link: 'https://www.optumcare.com/state-networks/locations/nevada/ocnv/patient-information/become-patient.html',
                newWindow: false,
                id: 1
            },

            {
                title: 'Community Centers',
                link: 'https://www.optumcare.com/state-networks/locations/nevada/ocnv/patient-information/patient-online-services.html',
                newWindow: false,
                id: 2
            }
        ]
    },
}

export default FOOTER_AUX_DATA;
