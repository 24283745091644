import React from "react";
import './button-col.styles.scss';
import ButtonComponent from "./button/button.component";

const ButtonColumns = ({data}) => {
    return (
        <>
            <div className={"col text-center"} key={"btnrow"}>
                {data.map(btn => {
                    return(

                        <ButtonComponent title={btn.title} buttonLight={btn.buttonLight} buttonLink={'#Inforow'+btn.id}/>

                    )
                })}
            </div>
        </>
    )
}

export default ButtonColumns