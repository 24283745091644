import React from 'react';
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faDotCircle} from "@fortawesome/free-solid-svg-icons";
import VideoComponentComponent from "../../header-video/video-component.component";
import ButtonComponent from "../../button-component/button/button.component";

const CardImgRightComponent = ({title, text, image, linkText, noBackground, buttonText, buttonLink}) => {
    return(
        <div className={"col px-2"}>
            <div className={noBackground ? "bg-transparent border-0 row" : "row px-4"}>
                <div className={"col-lg-6 pl-3 pt-2 align-items-center"} style={{borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px", height:"350px", background: "white",}}>
                    <h3 className={"mt-5"}>{title}</h3>
                    <h4 className={"font-body-2 mt-3 new-line"}>{text}</h4>
                    {buttonText ? <ButtonComponent buttonLight={true} buttonLink={buttonLink} title={buttonText} /> : null}
                    { linkText ? (<Card.Text style={{background:"none"}}><a href={"#"}>{linkText}<FontAwesomeIcon className={"ml-3"} icon={faChevronRight}/></a></Card.Text>) : true  }
                </div>
                <div className={"col-lg-6"} style={{borderTopRightRadius: "15px", borderBottomRightRadius: "15px", height:"350px", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${image})`}}>

                </div>
            </div>
        </div>
    )
}

export default CardImgRightComponent