import React from "react";
import {Accordion} from "react-bootstrap";
import './faq-dropdown.styles.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleDown, faChevronDown} from "@fortawesome/free-solid-svg-icons";

const FaqDropdownCol = ({data}) => {

    return(
        <Accordion>
            <Accordion.Item eventKey="0" className={"faq-item"}>
                <Accordion.Header className={"mx-3"}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"d-flex"}>
                            <h6>
                                Are you an insurance company?
                            </h6>
                        </div>
                        <div className={"d-flex"}>
                            <span className={"mx-auto"}>
                                <FontAwesomeIcon icon={faChevronDown} color={"#0C55B8"} size={"xs"}/>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body className={"mx-3"}>
                    <h6 className={"font-body-2"}>
                        No. The HCC provides direct care to patients at our clinic location.
                        Optum Care Network–Monarch is a health care services network.
                        Through the network, you can access hospitals, urgent care centers and labs.
                        Your insurance plan handles your benefits and copays. Optum works with your insurance to take care of authorizations, referrals, billing and claims.
                    </h6>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className={"faq-item"}>
                <Accordion.Header className={"mx-3"}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"d-flex"}>
                            <h6>
                                Are specialists available at the HCC?
                            </h6>
                        </div>
                        <div className={"d-flex"}>
                            <span className={"mx-auto"}>
                                <FontAwesomeIcon icon={faChevronDown} color={"#0C55B8"} size={"xs"}/>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body className={"mx-3"}>
                    <h6 className={"font-body-2"}>
                        Yes. We employ several specialists at the clinic and also work with leading specialists in the area to provide care to our patients.
                        To find a specialist, you can talk to your primary care provider or call Optum directly.
                        Please let customer service know that you’re an HCC patient.
                        You can call Monday through Friday, 8:30 a.m. to 5:00 p.m. Holiday hours may vary. During after hours, please leave a message. They’ll likely return your call the next business day.
                        The customer service numbers are listed below:
                    </h6>
                    <br />
                    <h6 className={"font-body-2"}>
                        English: <a href={"tel:+18887672222"}>1-888-767-2222</a>
                    </h6>
                    <h6 className={"font-body-2"}>
                        TTY: <a href={"tel:+18007352922"}>1-800-735-2922</a>
                    </h6>
                    <h6 className={"font-body-2"}>
                        Spanish: <a href={"tel:+18886627476"}>1-888-662-7476</a> TTY <b>711</b>
                    </h6>
                    <h6 className={"font-body-2"}>
                        Vietnamese: <a href={"tel:+18772227401"}>1-877-222-7401</a> TTY <b>711</b>
                    </h6>
                    <h6 className={"font-body-2"}>
                        Seniors: <a href={"tel:+18774666627"}>1-877-466-6627</a> TTY <b>711</b>
                    </h6>
                    <h6 className={"font-body-2"}>
                        CalOptima: <a href={"tel:+18886567523"}>1-888-656-7523</a> TTY <b>711</b>
                    </h6>
                    <br />
                    <h6 className={"font-body-2"}>
                        You can also write to:
                        <br />
                        Optum
                        <br />
                        Attn: Customer Service
                        <br />
                        Mail Stop 16 11
                        <br />
                        Technology Drive
                        <br />
                        Irvine, CA 92618
                    </h6>
                    <br/>
                    <h6 className={"font-body-2"}>
                        We want to make sure you understand everything about your care.
                        Please let us know if you need anything in other languages or large print.
                        We also have sign language interpreters, and we can read through materials if you’re sight impaired.
                    </h6>

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className={"faq-item"}>
                <Accordion.Header className={"mx-3"}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"d-flex"}>
                            <h6>
                                Who helps with specialists and authorizations?
                            </h6>
                        </div>
                        <div className={"d-flex"}>
                            <span className={"mx-auto"}>
                                <FontAwesomeIcon icon={faChevronDown} color={"#0C55B8"} size={"xs"}/>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body className={"mx-3"}>
                    <h6 className={"font-body-2"}>
                        Grecia Nuñez, senior ambassador, can help with specialists, authorizations, referrals, events, Medicare education and more.
                        She has a master’s degree in public health and speaks Spanish.
                        For help with any of the listed services, please email Grecia at <a href={"mailto:gnunez@mhealth.com"}>gnunez@mhealth.com.</a>
                    </h6>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className={"faq-item"} id={"Insurance"}>
                <Accordion.Header className={"mx-3"}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"d-flex"}>
                            <h6>
                                What insurances are accepted?
                            </h6>
                        </div>
                        <div className={"d-flex"}>
                            <span className={"mx-auto"}>
                                <FontAwesomeIcon icon={faChevronDown} color={"#0C55B8"} size={"xs"}/>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body className={"mx-3"}>
                    <h6 className={"font-body-2"}>
                        The HCC accepts most insurances, including Original Medicare, TRICARE, Medicare Supplemental plans, and private pay. Contact your health plan to see if your health plan is accepted.
                    </h6>
                    <br />
                    <h6 className={"font-body-2"}>
                        Aetna
                        <br/>
                        Fridays, 11 a.m.–12 p.m.
                        <br />
                        <a href={"tel:+15627956237"}>1-562-795-6237</a> TTY <b>711</b>
                    </h6>
                    <br />
                    <h6 className={"font-body-2"}>
                        Anthem
                        <br />
                        Tuesdays and Thursdays, 9 a.m.–12 p.m.
                        <br />
                        <a href={"tel:+15627956247"}>1-562-795-6247</a> TTY <b>711</b>
                    </h6>
                    <br />
                    <h6 className={"font-body-2"}>
                        Alignment
                        <br />
                        Wednesdays, 9 a.m.–1 p.m.
                        <br />
                        <a href={"tel:+15627956241"}>1-562-795-6241</a> TTY <b>711</b>
                    </h6>
                    <br />
                    <h6 className={"font-body-2"}>
                        Humana
                        <br />
                        Mondays, 9 a.m.–1 p.m.
                        <br />
                        <a href={"tel:+15627956240"}>1-562-795-6240</a> TTY <b>711</b>
                    </h6>
                    <br />
                    <h6 className={"font-body-2"}>
                        SCAN
                        <br />
                        Monday–Thursday, 9 a.m.–1 p.m.
                        <br />
                        <a href={"tel:+15627956205"}>1-562-795-6205</a> TTY <b>711</b>
                    </h6>
                    <br />
                    <h6 className={"font-body-2"}>
                        UnitedHealthcare
                        <br />
                        Tuesdays and Thursdays, 9 a.m.–12 p.m.
                        <br />
                        Fridays, 11 a.m.–2 p.m.
                        <br />
                        <a href={"tel:+15627956250"}>1-562-795-6250</a> TTY <b>711</b>
                    </h6>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )

}

export default FaqDropdownCol