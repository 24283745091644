import heroImage from '../../img/placeholders/hero-example.png';
import searchImage from '../../img/search_placeholder.png';
import imageUrl1 from "../../img/hcc/cardrow1.png";
import imageUrl2 from "../../img/hcc/cardrow2.png";
import imageUrl3 from "../../img/hcc/cardrow3.png";
import imageUrlRight1 from '../../img/hcc/home-urgent.png';
import imageUrlRight2 from '../../img/hcc/home-insurance.png';
import imageCareCenter from '../../img/hcc/home-lobby.png';
import newsPlaceholder from '../../img/hcc/news-placeholder.png';
import calendarPdf from '../../files/April_2024_Helath_Care_Center_Event_Calendar.pdf';

const HOMEPAGE_DATA = {
    homePage: {
        title: "Optum HCC - Home"
    },
    highlightTitle: {
        title: "Your Optum Health Care Center",
        subtitle: "",
        highlight: {
            title: "About HCC",
            subtitle: "Virtual care",
            text: "As you continue your journey to be your healthiest self, we’re here to support all of your health needs. This means personalized primary and specialty care, convenient on-site services as well as community center events and groups to keep you active and engaged. Whatever your health goals are, we want to help you meet them. Because when you’re feeling your best, you can live a fuller, healthier life.",
            buttonText: "Learn more",
            buttonLink: "/about",
            image: imageCareCenter,
        },
    },
    cardsRow2: {
        title: "News and updates",
        cards: [
            {
                id: 1,
                title: "Lorem Ipsum",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut purus sit amet tortor rutrum rutrum sit amet nec leo. In eget ornare tellus. Quisque lobortis auctor blandit. Maecenas sed ex neque.",
                linkText: "Read more",
                linkUrl: "/#",
                image: newsPlaceholder,
                inRowSize: "4",
            },
            {
                id: 2,
                title: "Lorem Ipsum",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut purus sit amet tortor rutrum rutrum sit amet nec leo. In eget ornare tellus. Quisque lobortis auctor blandit. Maecenas sed ex neque.",
                linkText: "Read more",
                linkUrl: "/#",
                image: newsPlaceholder,
                inRowSize: "4",
            },
            {
                id: 3,
                title: "Lorem Ipsum",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut purus sit amet tortor rutrum rutrum sit amet nec leo. In eget ornare tellus. Quisque lobortis auctor blandit. Maecenas sed ex neque.",
                linkText: "Read more",
                linkUrl: "/#",
                image: newsPlaceholder,
                inRowSize: "4",
            },
        ],
    },
    infoCardsRow: {
      title: "Lorem Ipsum Dolor Sit",
        cards: [
            {
                id: 1,
                title: "Convenient urgent care nearby",
                text: `Optum – Seal Beach Family<br/>1198 Pacific Coast Hwy. Ste. I<br/>Seal Beach, Ca, 90740`,
                buttonText: "Location info",
                buttonLink: "https://www.optumcare.com/ocnc/facilities/ca/seal-beach/1198-pacific-coast-hwy-optum-seal-beach-family-urgent-care-ste-i-urgent-care-55f21825-560c-4d7f-a826-f6d300ac589f-1198pacificcoasthwy",
                image: imageUrlRight1,
                inRowSize: "4",
            },
            {
                id: 2,
                title: "A team dedicated to you",
                text: "At the HCC, you have a team that listens to you and understands your needs.",
                buttonText: "Learn more",
                buttonLink: "/providers",
                image: imageUrlRight2,
                inRowSize: "4",
            },
        ],
    },
    cardsRow: {
        title: "",
        cards: [
           /* {
                id: 1,
                title: "",
                text: "<span class='font-navy-blue bold'>When:</span>\n" +
                    "Thursday, October 6, 2022\n <br>" +
                    "<span class='font-navy-blue bold'>Location:</span>\n" +
                    "Optum–Seal Beach Leisure World <br> Health Care Center\n <br>" +
                    "1661 Golden Rain Rd.\n" +
                    "Seal Beach, CA 90740",
                linkText: "Download your flu shot form here",
                linkUrl: "https://optum-ca.s3.us-west-1.amazonaws.com/Optum+HCC+Flu+Clinic+Consent.pdf",
                image: imageUrl1,
                inRowSize: "12",
                linkTarget:"self",
            }, */

                {
                id: 1,
                title: "Event calendar",
                text: "From learning a new hobby, to health classes and support groups, there’s plenty for you to take part in at the HCC. Our events and classes are open to all residents of Leisure World.",
                linkText: "View our event calendar",
                linkUrl: calendarPdf,
                image: imageUrl1,
                inRowSize: "4",
            },
            {
            id: 2,
            title: "Convenient urgent care nearby",
            text: `Optum – Seal Beach Family<br/>1198 Pacific Coast Hwy., Ste. I<br/>Seal Beach, CA 90740`,
            linkText: "Location info",
            linkUrl: "https://www.optumcare.com/ocnc/facilities/ca/seal-beach/1198-pacific-coast-hwy-optum-seal-beach-family-urgent-care-ste-i-urgent-care-55f21825-560c-4d7f-a826-f6d300ac589f-1198pacificcoasthwy",
            image: imageUrl2,
            inRowSize: "4",
        },
        {
            id: 3,
                title: "A team dedicated to you",
            text: "At the HCC, you have a team that listens to you and understands your needs.",
            linkText: "Learn more",
            linkUrl: "/providers",
            image: imageUrl3,
            inRowSize: "4",
        },
            /*
           {
                id: 2,
                title: "We’re insurance friendly",
                text: "The HCC accepts most insurances, including Original Medicare, TRICARE, Medicare supplemental plans and private pay. Learn more about the health plans we accept.",
                linkText: "Learn more",
                linkUrl: "/patient-resources#Insurance",
                image: imageUrl2,
                inRowSize: "4",
            },
            {
                id: 3,
                title: "When you need us, we’re here",
                text: "We know some health questions are urgent. With our 24-hour hotline, you can speak to a nurse anytime to get advice on any health concerns.",
                linkText: "Learn more",
                linkUrl: "/#",
                image: imageUrl3,
                inRowSize: "4",
            },
             */
        ],
    },
    hero: {
            id: 1,
            title: 'Welcome to your Optum Health Care Center (HCC)',
            text:'We’re honored to care for our Leisure World community.',
            backgroundImg: heroImage,
            search: searchImage,
        },
    buttons: [
        {
            id: 1,
            title: 'test',
        },
        {
            id: 2,
            title: 'test',
        },
        {
            id: 3,
            title: 'test',
        },
        {
            id: 4,
            title: 'test',
        },
        {
            id: 5,
            title: 'test',
        },
        {
            id: 6,
            title: 'test',
        },
    ],
    buttonsLight: [
        {
            id: 1,
            title: 'Cardiology',
            buttonLight: true,
        },
        {
            id: 2,
            title: 'Chiropractic Services',
            buttonLight: true,

        },
        {
            id: 3,
            title: 'Dermatology',
            buttonLight: true,
        },
        {
            id: 4,
            title: 'Dietician',
            buttonLight: true,
        },
        {
            id: 5,
            title: 'Endocrinology',
            buttonLight: true,
        },
        {
            id: 6,
            title: 'Gastroenterology',
            buttonLight: true,
            onRowSize: "4",
        },
        {
            id: 7,
            title: 'Massage therapy',
            buttonLight: true,
            onRowSize: "4",
        },
        {
            id: 8,
            title: 'Mental health',
            buttonLight: true,
            onRowSize: "4",
        },
        {
            id: 9,
            title: 'Nephrology',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 10,
            title: 'Optometry and Ophtalmology',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 11,
            title: 'Orthopedics',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 12,
            title: 'Pain management',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 13,
            title: 'Pediatry',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 14,
            title: 'Pulmonology',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 15,
            title: 'Rheumatology',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 16,
            title: 'Wound care',
            buttonLight: true,
            onRowSize: "3",
        },
    ]
}

export default HOMEPAGE_DATA;
