import React from "react";

const LocationsTableCellComponent = ({data}) => {
    return (
        <>
            <table className="table table-striped" style={{width: "100%"}}>
                <thead>
                <tr>
                    <th scope="col"><h5>Urgent care</h5></th>
                    <th scope="col"><h5>Address</h5></th>
                    <th scope="col"><h5>Operating days</h5></th>
                    <th scope="col"><h5>Operating hours</h5></th>
                </tr>
                </thead>
                <tbody>
                { data.cells.map( cell => {
                    return(
                        <tr className={"font-table"} style={{height: "190px", minWidth: "190px"}}>
                            <th scope="row"> <a href={cell.locationUrl}><h6 className={"font-blue"}>{cell.locationName}</h6></a></th>
                            <td>
                                <div>
                                    <span className={"font-body-1"}>{cell.locationAddress}</span>
                                </div>
                                <div>
                                    <span className={"font-body-1"}>{cell.locationCity} {cell.locationZip}</span>
                                </div>
                                <div>
                                    <span className={"font-body-1"}>{cell.locationPhone}</span>
                                </div>
                            </td>
                            <td><span className={"font-body-1"}>{cell.operatingDays.map( day => {
                                return(
                                    <div>{day}</div>
                                )} )}</span></td>
                            <td><span className={"font-body-1"}>{cell.operatingHours.map( hour => {
                                return(
                                    <div>{hour}</div>
                                )} )}</span></td>
                        </tr>
                    )
                }) }
                </tbody>
            </table>
        </>
    )
}

export default LocationsTableCellComponent