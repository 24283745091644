import imageUrl from '../../img/home-10.png';

const FAQ_DATA = {
    cards: [
        {
            id: 1,
            title: "title lorem",
            text: "some text lorem ipsum",
            image: imageUrl,
            inRowSize: "3",
            imgOverlay: true,
        },
        {
            id: 2,
            title: "title lorem 2",
            text: "some text lorem ipsum",
            image: imageUrl,
            inRowSize: "3",
            imgOverlay: true,
            linkText: "learn more"
        },
        {
            id: 3,
            title: "title lorem 3",
            text: "some text lorem ipsum",
            image: imageUrl,
            inRowSize: "3",
            imgOverlay: true,
        },
        {
            id: 4,
            title: "title lorem 3",
            text: "some text lorem ipsum",
            image: imageUrl,
            inRowSize: "3",
            imgOverlay: true,
        },
    ]
}

export default FAQ_DATA