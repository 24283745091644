import React from 'react';
import HighlightSingle from "../highlights/highlights-single.component";
import ButtonComponent from "../button-component/button/button.component";

class HighlightsWithTitle extends React.Component{
    info= this.props.info
    render() {
        return (
            <>
                <div className={"row my-5"}>
                    <div className={"col-12 text-center mb-2"}>
                        <span className={"font-body-2 font-navy-blue"}><b>{this.info.subtitle}</b></span>
                    </div>
                    <div className={"col-12 text-center"}>
                        <h1>{this.info.title}</h1>
                    </div>
                </div>
                <div className={"row align-items-center mb-5"}>
                    <HighlightSingle isLeft={this.props.isLeft} info={this.info.highlight}/>
                </div>
            </>
        )
    }
}

export default HighlightsWithTitle