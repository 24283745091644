import React from 'react';
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../button-component/button/button.component";

const CardOverlayComponent = ({title, text, image, linkText, noBackground, buttonText, inRowSize}) => {
    return(
        <div className={inRowSize ? (`col-${inRowSize}`) : false}>
            <Card style={{height: "530px", maxWidth: "100%"}} className={"text-left align-items-center"}>
                <Card.Img style={{borderRadius: "10px", width:"100%", height:"100%"}} variant={"top"} src={image} />
                <Card.ImgOverlay className={"text-left d-flex align-items-center"}>
                    <Card.Body>
                        <h3 className={"font-white"}>{title}</h3>
                        <h1 className={"font-white"}>{text}</h1>
                        { buttonText ? ( <ButtonComponent title={buttonText} buttonLight={true}/> ) : false }
                        <br/>
                        <br/>
                        { linkText ? (<Card.Text style={{background:"none"}}><a href={"#"}>{linkText}<FontAwesomeIcon className={"ml-3"} icon={faChevronRight}/></a></Card.Text>) : true  }
                    </Card.Body>
                </Card.ImgOverlay>
            </Card>
        </div>
    )
}

export default CardOverlayComponent