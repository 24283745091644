import React from 'react';
import FOOTER_AUX_DATA from './footer-bottom-aux.data'
import './footer-bottom-aux.styles.scss'

const data = FOOTER_AUX_DATA

const FooterBottomAuxComponent = ({imageUrl, linkUrl, bodyCopy, title, id, buttonText, columnQuarter, isBlank}) => (

        <div className="row footer-aux d-none">
            <div className="col-md-2 col-sm-12 no-padding mobile-first">
              <h6 className={"pr-5"}>{data.section1.title}</h6>
              <ul className="top-footer-menu mt-3 pr-5">
                  {
                      data.section1.items.map( ({id, title, link}) => (
                          <li className={"my-2"} key={id}><a href={link} target="_blank"><span className={"font-body-3"}>{title}</span></a></li>
                      ))
                  }
              </ul>
            </div>
            <div className="col-md-2 col-sm-12 no-padding">
                <h6 className={"pr-5"}>{data.section2.title}</h6>
                <ul className={"mt-2"}>
                {
                    data.section2.items.map( ({id, title, link}) => (
                        <li key={id}><a href={link} target="_blank"><span className={"font-body-3"}>{title}</span></a></li>
                    ))
                }
                </ul>
            </div>
            <div className="col-md-2 col-sm-12 no-padding">
                <h6 className={"pr-5"}>{data.section3.title}</h6>
                <ul className={"mt-2"}>
                    {
                        data.section3.items.map( ({id, title, link}) => (
                            <li key={id}><a href={link} target="_blank"><span className={"font-body-3"}>{title}</span></a></li>
                        ))
                    }
                </ul>
            </div>
        </div>
)

export default FooterBottomAuxComponent;
