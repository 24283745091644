import heroImage from '../../img/hcc/about-hcc-hero.png';
import searchImage from '../../img/search_placeholder.png';
import imageHighlight1 from '../../img/hcc/about-2.png';
import imageHighlight2 from '../../img/hcc/about-1.png';
import imageHighlight3 from '../../img/hcc/about-3.png';

const ABOUT_DATA = {
    breadcrumb: {
        items: [
            {
                id: 1,
                linkUrl: "/about",
                title: "About",
            }
        ]
    },
    page: {
        title: "Optum HCC - About",
    },
    highlight1: {
            title: "Keeping our Leisure World community healthy",
            subtitle: "",
            text: "The HCC has been serving the Leisure World community for more than 30 years. In 2018, Optum took over the HCC to offer more options to our residents. At the HCC, you have a team that listens to you and understands your needs. The care you receive is designed especially for you. Our priority is to support your whole health. This means going beyond the care you receive from your doctors. That’s why we offer many health programs and social activities to keep you connected and enjoying life. We want you to feel strong — physically, mentally and emotionally. With us, all your health needs are all in one place. ",
            image: imageHighlight2,
    },
    highlight2: {
        title: "Optum Care Network–Monarch",
        subtitle: "",
        text: "The HCC is part of Optum Care Network–Monarch. If you’re on a Medicare Advantage plan or a commercial plan, you can access our doctors as a member of Optum Care Network–Monarch. The network has 750 primary care doctors and 1,750 specialists, 23 contracted hospitals and 75 urgent care centers. Los Alamitos Medical Center and MemorialCare Long Beach are the closest hospitals to HCC. ",
        image: imageHighlight1,
    },
    highlight3: {
        title: "Enjoy the many offerings at our community center",
        subtitle: "",
        text: "The community center is a place to stay active and be healthy. You can attend a workshop series, take a health class or join a support group. We have a variety of options so there’s something for everyone. Our events and classes are open to all residents of Leisure World. Take some time for yourself to try something new, meet people and have fun.",
        image: imageHighlight3,
    },
    hero: {
        id: 1,
        title: 'About HCC',
        text:'Our clinic was created to meet the needs of all our patients.',
        backgroundImg: heroImage,
        search: searchImage,
    },
}

export default ABOUT_DATA;
