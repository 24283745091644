import React from 'react';
import TOOLKIT_DATA from "./primary-care.data";
import {Helmet} from "react-helmet";
import HeaderHero from "../../components/header-hero/header-hero.component";
import CardsRow from "../../components/cards-row/cards-row.component";
import InfoCardsRow from "../../components/info-cards-row/info-cards-row.component";
import HighlightsWithTitle from "../../components/highlight-with-title/highlights-with-title.component";
import HighlightsRowComponent from "../../components/highlights/highlights-row.component";
import PRIMARY_DATA from "./primary-care.data";
import BreadcrumbTop from "../../components/breadcrumb-top/breadcrumb-top.component";

class PrimaryCarePage extends React.Component {
    state = PRIMARY_DATA;
    render() {
        const {hero, highlight1, highlight2, highlight3, page, breadcrumb} = this.state;
        return(
            <>
                <main className={"page-body"}>
                    <Helmet>
                        <title>{page.title}</title>
                    </Helmet>

                    <div className={"container-fluid"}>
                        <BreadcrumbTop info={breadcrumb}/>
                        <HeaderHero info={hero} />
                        <HighlightsRowComponent isLeft={true} info={highlight1} />
                        <HighlightsRowComponent isLeft={false} info={highlight2}/>
                        <HighlightsRowComponent isLeft={true} info={highlight3} />
                    </div>
                </main>
            </>
        );
    }
}

export default PrimaryCarePage;
