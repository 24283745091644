import './App.css';
import './main'
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.styles.scss'
import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Toolkit from "./pages/toolkit/toolkit.component";
import NavigationComponent from "./components/navigation/navigation.component";
import FooterAuxComponent from "./components/footer/footer-aux/footer-aux.component";
import FooterBottom from "./components/footer/footer-bottom.component";
import Homepage from "./pages/homepge/homepage.component";
import AboutPage from "./pages/about/about.component";
import SpecialtyCarePage from "./pages/specialty-care/specialty-care.component";
import PrimaryCarePage from "./pages/primary-care/primary-care.component";
import OnSiteServicesPage from "./pages/onsite-services/onsite-services.component";
import ProvidersPage from "./pages/providers-gallery/providers-gallery.component";
import LocationPage from "./pages/location/location.component";
import PatientResourcesPage from "./pages/patient-resources/patient-resources.component";
import ProviderTemplatePage from "./pages/providers-single-page/providers-template.component";
import ScrollToTop from './components/scroll-to-top/scroll-to-top.component';


class App extends React.Component {
    render(){
        return (
            <main>
                <NavigationComponent/>
                <ScrollToTop />
                <Switch>
                    <Route exact
                           path='/'
                           component={Homepage}
                    />
                    <Route exact
                           path='/toolkit'
                           component={Toolkit}
                    />
                    <Route exact
                           path='/specialty'
                           component={SpecialtyCarePage}
                    />
                    <Route exact
                           path='/about'
                           component={AboutPage}
                    />
                    <Route exact
                           path='/primary'
                           component={PrimaryCarePage}
                    />
                    <Route exact
                           path='/onsite'
                           component={OnSiteServicesPage}
                    />
                    <Route exact
                           path='/providers'
                           component={ProvidersPage}
                    />
                    <Route exact
                           path='/location'
                           component={LocationPage}
                    />
                    <Route exact
                           path='/patient-resources'
                           component={PatientResourcesPage}
                    />
                    <Route path='/providers/:providerId' component={ProviderTemplatePage} />
                </Switch>
                <FooterAuxComponent />
                <FooterBottom />
            </main>
        );
    }
}

export default App;
