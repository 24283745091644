import React from 'react';
import TOOLKIT_DATA from "./homepage.data";
import {Helmet} from "react-helmet";
import HeaderHero from "../../components/header-hero/header-hero.component";
import CardsRow from "../../components/cards-row/cards-row.component";
import InfoCardsRow from "../../components/info-cards-row/info-cards-row.component";
import HighlightsWithTitle from "../../components/highlight-with-title/highlights-with-title.component";
import HOMEPAGE_DATA from "./homepage.data";
import HeaderVideo from "../../components/header-video/header-video.component";
import FormComponent from '../../components/form/form.component';

class Homepage extends React.Component {
    state = TOOLKIT_DATA;
    data = HOMEPAGE_DATA;
    render() {
        return(
            <>
                <main className={"page-body"}>
                    <Helmet>
                        <title>{this.data.homePage.title}</title>
                    </Helmet>

                    <div className={"container-fluid"}>
                        <HeaderVideo info={this.data.hero} />
                        {/*  <HeaderHero info={this.data.hero} />
                        <HeaderVideo info={this.data.hero} /> */}
                        <CardsRow info={this.data.cardsRow} />
                        {/* <InfoCardsRow info={this.data.infoCardsRow} /> */}
                        <HighlightsWithTitle info={this.data.highlightTitle} />
                        {/*<CardsRow info={this.data.cardsRow2}/> */}

                    </div>
                </main>
            </>
        );
    }
}

export default Homepage;
