import React from 'react';
import ProviderThumbnail from "./provider-thumbnail/provider-thumbnail.component";
import "./provider-gallery.styles.scss"
import {connect} from "react-redux";
import { createStructuredSelector } from 'reselect';
import { selectProviderSections } from "../../redux/provider/provider.selectors";
import DividerArrowDownComponent from "../divider-arrow-down/divider-arrow-down.component";
import { getProvidersBySpecialty } from "../../main";

const ProviderGallery = ({ sections: providers }) => {

    var locationHcc = getProvidersBySpecialty('HCC');
    var locationSealbeach = getProvidersBySpecialty('Seal Beach');


    return (
        <div className="provider-gallery-component">
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Our providers here in Leisure World</h1>
                </div>
                {
                    locationHcc.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Our providers at Seal Beach Village (just outside the gates)</h1>
                </div>
                {
                    locationSealbeach.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

            {/*
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Joint Replacement and Revisions</h1>
                </div>

                {
                    jointReplacement.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Sports Medicine</h1>
                </div>

                {
                    sportsMedicine.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Shoulder</h1>
                </div>

                {
                    shoulder.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Elbow</h1>
                </div>

                {
                    elbow.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Spine</h1>
                </div>

                {
                    spine.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Hips and Pelvis</h1>
                </div>

                {
                    hipsAndPelvis.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Knee</h1>
                </div>

                {
                    knee.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Trauma and Fracture Care</h1>
                </div>

                {
                    traumaFracture.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Pediatric</h1>
                </div>

                {
                    pediatric.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            <div className="row gallery-row bg-beige centered-div my-4">
                <div className={"col-lg-12 text-center mt-5 mb-3"}>
                    <h1>Oncology</h1>
                </div>

                {
                    oncology.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
            */}

        </div>)
};

const mapStateToProps = createStructuredSelector({
    sections: selectProviderSections
});

export default connect(mapStateToProps)(ProviderGallery);
