import heroImage from '../../img/hcc/primary-care-hero.png';
import searchImage from '../../img/search_placeholder.png';
import imageHighlight1 from '../../img/placeholders/highlight1.png';
import imageHighlight2 from '../../img/placeholders/highlight2.png';
import imageHighlight3 from '../../img/placeholders/highlight7.png';
import imageHighlight4 from '../../img/placeholders/highlight4.png';
import imageHighlight5 from '../../img/placeholders/highlight5.png';
import imageHighlight6 from '../../img/placeholders/highlight6.png';

const PRIMARY_DATA = {
    breadcrumb: {
        items: [
            {
                id: 1,
                linkUrl: "/",
                title: "Care services",
            },
            {
                id: 2,
                linkUrl: "#",
                title: "Primary care",
            }
        ]
    },
    page: {
        title: "Optum HCC - Primary care",
    },
    highlight1: {
        title: "Care designed with you in mind ",
        subtitle: "",
        text: "Our primary care team is specially trained to understand the unique health needs of seniors.  They help you understand how to make the right decisions for your health. They take the time to get to know you and will speak in the language that's most comfortable for you. This is why our staff are fluent in several languages including English, German, Korean, Russian and Spanish. ",
        image: imageHighlight5,
    },
    highlight2: {
        title: "Helping you live your healthiest life",
        subtitle: "",
        text: "With Optum, you’re at the center of a health care team led by your primary care provider.  Your doctor will work with you to put together a care plan personalized to fit your needs and lifestyle.  And, they’ll help you to continue to meet your health goals over time.",
        image: imageHighlight3,
    },
    highlight3: {
        title: "A focus on your overall health",
        subtitle: "",
        text: "We know that caring for you with injury or illness is as important as helping you take preventive steps toward overall wellness. Your care team of medical assistants and your primary care doctor work together to keep you healthier. For every part of your health, we’re here when you need us.",
        image: imageHighlight4,
    },
    hero: {
        id: 56,
        title: 'Primary care',
        text:'We’re here to support your whole health through every stage in life.',
        backgroundImg: heroImage,
        search: searchImage,
    },
}

export default PRIMARY_DATA;
