import React from 'react';
import ButtonSmallComponent from "../button-component/button/button-small.component";
import FAQ_DATA from "./faq-row.data";
import CardsCol from "../card-component/card-col.component";

class FAQRow extends React.Component{
    state = FAQ_DATA;
    render() {
        return(
            <div className={"row justify-content-center align-items-center bg-beige py-5"}>
                <div className={"col-12 mt-3 text-center"}>
                    <h3>Lorem ipsum dolor sit amet</h3>
                    <h1>Lorem ipsum dolor sit amet</h1>
                    <h3>Lorem ipsum dolor sit amet</h3>
                </div>
                <div className={"col-12 mt-5"}>
                    <div className={"row justify-content-around"}>
                        <CardsCol data={this.state}/>
                    </div>
                </div>
                <div className={"col-12 text-center mt-5"}>
                    <ButtonSmallComponent buttonWhite={true} title={"Learn More"}/>
                </div>
            </div>

        )
    }
}

export default FAQRow