import { createSelector } from 'reselect';

const PROVIDER_ID_MAP = {
    'sophia-chun': 1,
    'ellen-dayon': 2,
    'rudolf-haider': 3,
    'david-park': 4,
    'nancy-smith': 5,
    'sullyvan-tang': 6,
    'steve-becker': 7,
    'chris-nguyen': 8
}

const selectProviders = state => state.provider;

export const selectProviderSections = createSelector(
    [selectProviders],
    provider => provider.sections
)

export const selectIndividualProvider = providerUrlParam =>
    createSelector(
        [selectProviderSections],
        providers =>
            providers.find(
                provider => provider.id === PROVIDER_ID_MAP[providerUrlParam]
            )
    );
