

const TOOLKIT_DATA = {
    buttons: [
        {
            id: 1,
            title: 'test',
        },
        {
            id: 2,
            title: 'test',
        },
        {
            id: 3,
            title: 'test',
        },
        {
            id: 4,
            title: 'test',
        },
        {
            id: 5,
            title: 'test',
        },
        {
            id: 6,
            title: 'test',
        },
    ],
    buttonsLight: [
        {
            id: 1,
            title: 'Cardiology',
            buttonLight: true,
        },
        {
            id: 2,
            title: 'Chiropractic Services',
            buttonLight: true,

        },
        {
            id: 3,
            title: 'Dermatology',
            buttonLight: true,
        },
        {
            id: 4,
            title: 'Dietician',
            buttonLight: true,
        },
        {
            id: 5,
            title: 'Endocrinology',
            buttonLight: true,
        },
        {
            id: 6,
            title: 'Gastroenterology',
            buttonLight: true,
            onRowSize: "4",
        },
        {
            id: 7,
            title: 'Massage therapy',
            buttonLight: true,
            onRowSize: "4",
        },
        {
            id: 8,
            title: 'Mental health',
            buttonLight: true,
            onRowSize: "4",
        },
        {
            id: 9,
            title: 'Nephrology',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 10,
            title: 'Optometry and Ophtalmology',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 11,
            title: 'Orthopedics',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 12,
            title: 'Pain management',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 13,
            title: 'Pediatry',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 14,
            title: 'Pulmonology',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 15,
            title: 'Rheumatology',
            buttonLight: true,
            onRowSize: "3",
        },
        {
            id: 16,
            title: 'Wound care',
            buttonLight: true,
            onRowSize: "3",
        },
    ]
}

export default TOOLKIT_DATA;
