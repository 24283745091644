import React from 'react';
import TOOLKIT_DATA from "./specialty-care.data";
import {Helmet} from "react-helmet";
import HeaderHero from "../../components/header-hero/header-hero.component";
import CardsRow from "../../components/cards-row/cards-row.component";
import InfoCardsRow from "../../components/info-cards-row/info-cards-row.component";
import HighlightsWithTitle from "../../components/highlight-with-title/highlights-with-title.component";
import ButtonRow from "../../components/button-row/button-row.component";
import FullInfoRow from "../../components/full-info-row/full-info-row.component";
import SPECIALTY_DATA from "./specialty-care.data";
import BreadcrumbTop from "../../components/breadcrumb-top/breadcrumb-top.component";
import HeaderIntro from "../../components/header-intro/header-intro.component";

class SpecialtyCarePage extends React.Component {
    state = SPECIALTY_DATA;
    render() {
        const {hero, buttonsLight, infoRows, page, breadcrumb} = this.state
        return(
            <>
                <main className={"page-body"}>
                    <Helmet>
                        <title>{page.title}</title>
                    </Helmet>

                    <div className={"container-fluid"}>
                        <BreadcrumbTop info={breadcrumb}/>
                        <HeaderHero info={hero} />
                        <HeaderIntro />
                        <ButtonRow info={buttonsLight} />
                        <FullInfoRow info={infoRows} />
                    </div>
                </main>
            </>
        );
    }
}

export default SpecialtyCarePage;
