import navLogo from "../../img/nav-logo.png";
import searchBar from '../../img/search_placeholder.png';

const NAVIGATION_DATA = {
    search: searchBar,
    navLogo: navLogo,
    homeUrl: '/',
    linkItems: [
        {
            id: 1,
            name: 'Care services',
            link: '#',
            children: [
                {
                    name: 'Primary care',
                    link: "/primary"
                },
                {
                    name: 'Specialty care',
                    link: "/specialty"
                },
                {
                    name: 'On-site services',
                    link: "/onsite"
                },
            ]
        },
        {
            id: 2,
            name: 'About the HCC',
            link: '/about',
            children: []
        },
        {
            id: 3,
            name: 'Providers',
            link: '/providers',
            children: []
        },
        {
            id: 4,
            name: 'Location',
            link: '/location',
            children: []
        },
        {
            id: 5,
            name: 'Patient resources',
            link: '/patient-resources',
            children: []
        },
    ],
    rightHtml: ``,
}

export default NAVIGATION_DATA;
