import heroImage from '../../img/hcc/onsite-hero.png';
import searchImage from '../../img/search_placeholder.png';
import imageHighlight1 from '../../img/placeholders/highlight1.png';
import imageHighlight2 from '../../img/hcc/more-services-radiology.png';
import imageHighlight3 from '../../img/hcc/more-services-phamacy.png';
import imageHighlight4 from '../../img/placeholders/highlight4.png';
import imageHighlight5 from '../../img/hcc/more-services-lab.png';
import imageHighlight6 from '../../img/hcc/more-services-pt.png';
import imageHighlight7 from '../../img/placeholders/highlight7.png';


const ONSITE_DATA = {
    breadcrumb: {
        items: [
            {
                id: 1,
                linkUrl: "/",
                title: "Care services",
            },
            {
                id: 2,
                linkUrl: "#",
                title: "On-site services",
            }
        ]
    },
    page: {
        title: "Optum HCC - On-site services",
    },
    highlight1: {
        title: "On-site lab",
        textLabPatch: true,
        subtitle: "",
        text: "",
        image: imageHighlight5,
        buttonText: "Call to schedule",
        buttonLink:"tel:1-562-493-9581",
    },
    highlight2: {
        title: "Radiology",
        subtitle: "",
        text: "We offer X-ray and ultrasound services in-office for faster diagnostic testing. Our expanded equipment means we can perform services on patients of any mobility type.",
        image: imageHighlight2,
        buttonText: "Call to schedule",
        buttonLink:"tel:1-562-493-9581",
    },
    highlight3: {
        title: "Pharmacy",
        subtitle: "",
        text: "You can pick up your medications and medical equipment at the pharmacy. We also sell everyday items like frozen meals, snacks, gifts and cards as a convenience to our patients. Call 1-562-493-9581, TTY ",
        textBoldPatch: "711 ",
        textPatch: "Monday–Friday, 9:00 a.m.–5:30 p.m.",
        image: imageHighlight3,
        buttonText: "Call to schedule",
        buttonLink:"tel:1-562-493-9581",
    },
    highlight4: {
        title: "Seniors' Choice Physical Therapy",
        subtitle: "",
        text: "Our therapists specialize in caring for seniors and offer therapy with your comfort level and health goals in mind. Call 1-562-493-9581, TTY ",
        textBoldPatch: "711 ",
        textPatch: ", Monday–Friday, 8 a.m.–5 p.m. (closed for lunch 12–1 p.m.).",
        image: imageHighlight6,
        buttonText: "Call to schedule",
        buttonLink:"tel:1-562-493-9581",
    },
    highlight5: {
        title: "Wellness center",
        subtitle: "",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequa",
        image: imageHighlight3,
        buttonText: "Call our wellness center",
        buttonLink:"tel:1-562-493-9581",
    },
    highlight6: {
        title: "Ophthalmology",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequa",
        image: imageHighlight2,
        buttonText: "Call to schedule",
        buttonLink:"tel:1-562-493-9581",
    },
    hero: {
        id: 6,
        title: 'More services in one place ',
        text:'In addition to your health care team, we provide several health care services right here at the HCC. ',
        backgroundImg: heroImage,
        search: searchImage,
    },
}

export default ONSITE_DATA;
