import React from "react";
import LOCATIONS_TABLE_DATA from "./locations-table.data";
import LocationsTableCellComponent from "./locations-table-cell.component";

class LocationsTable extends React.Component{
    state = LOCATIONS_TABLE_DATA;
    render() {
        return (
            <div className={"row justify-content-center"} id={'locations'}>
                <div className={"row px-2 px-md-5 py-0 py-md-5 text-center"}>
                    <h1>Convenient urgent care nearby to serve you </h1>
                </div>
                <div className={"row px-2 px-md-5 py-0 pt-md-5 w-100"}>
                    <LocationsTableCellComponent data={this.state}/>
                </div>

            </div>
        )
    }
}

export default LocationsTable