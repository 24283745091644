import React from 'react';
import PROVIDERS_DATA from "./providers-highlight.data";
import CardComponent from "../card-component/card/card.component";

class ProvidersHighlight extends React.Component{
    state = PROVIDERS_DATA;
    render() {
        return (
            <>
                <div className={"row py-5 bg-orange"}>
                    <div className={"col-12 text-center"}>
                        <h1 className={"font-white"}>Meet your providers</h1>
                        <h3 className={"font-white mt-5"}>Lorem ipsum dolor sit amet</h3>
                    </div>
                </div>
                <div className={"row align-items-center py-5 bg-orange"}>
                    {this.state.sections.map(provider => {
                        return(
                            <CardComponent inRowSize={"3"} title={provider.providerName} text={provider.providerName} image={provider.imageUrl} buttonLight={true} buttonText={"Learn More"} />
                        )
                    })}
                </div>
            </>
        )
    }
}

export default ProvidersHighlight