import React from 'react';

const FullInfoComponent = ({title, text, id}) => {
    return(
        <div id={'Inforow'+id} className={"col-12 text-left border-bottom py-4"} style={{minHeight: "150px", borderBottom: "1px solid linear-gradient(90deg, rgba(255,255,255,1) 5%, rgba(0,0,0,1) 50%, rgba(255,255,255,1) 95%)"}} >
          <h1>{title}</h1>
            <h6 className={"font-body-2"}>{text}</h6>
        </div>
    )
}

export default FullInfoComponent