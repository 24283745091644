import React from "react";
import virtualTour from '../../img/full-virtual-tour-hero.m4v';

export default function ModalVideo() {
    return (
            <video controls={true} autoPlay={true} width={"100%"}>
                <source
                    src={virtualTour}
                    type="video/mp4"
                />
            </video>
    );
}