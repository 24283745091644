import React from 'react';
import FOOTER_AUX_DATA from "./footer-aux.data";
import CardsCol from "../../card-component/card-col.component";

class CardsRow extends React.Component {
    state = FOOTER_AUX_DATA
    render() {
        return (
            <div className={"container-fluid"}>
                <div className={"row py-5 bg-lightblue px-0 px-md-5"}>
                    <CardsCol data={this.state}/>
                </div>
            </div>

        )
    }
}

export default CardsRow