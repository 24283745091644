import React from "react";
import '../header-hero/header-hero.styles.scss';
import HEADER_HERO_DATA from "./header-hero.data";

class HeaderHero extends React.Component {
    state = HEADER_HERO_DATA;
    info = this.props.info;
    render() {
        return (<div className={"row hero-div"}>
            <div className={"hero-div hero-wrapper"} style={{
                position:"absolute", left: "0",
                width: "100%",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url(${this.props.info.backgroundImg})`,
                //filter: "contrast(50%) brightness(50%)",
            }}>
            </div>
                <div className={"row w-100 hero-div align-items-center mb-5"} style={{position: "relative"}}>

                    <div className={"col-12 col-md-6 pl-5 pl-md-5"} style={{paddingLeft: "100px"}} >
                        <h1 className={"font-white"}>{this.props.info.title}</h1>
                        <span className={"font-body-1 font-white"}><b>{this.props.info.text}</b></span>
                    </div>
                    <div className={"col-md-6"}>

                    </div>
                </div>


        </div>


        )
    }
}

export default HeaderHero