import imageUrl from '../../img/placeholderight.png';

const CARDS_LEFT_DATA = {
    cards: [
        {
            id: 1,
            title: "Title Lorem",
            text: "some text lorem ipsum dolor",
            image: imageUrl,
            buttonText: "sample",
        },
        {
            id: 2,
            title: "Title Lorem 2",
            text: "some text lorem ipsum",
            image: imageUrl,
            buttonText: "sample",
        },
    ]
}

export default CARDS_LEFT_DATA