import React from "react";
import CARDS_LEFT_DATA from "./info-cards-row.data";
import CardImgRightComponent from "../card-component/card-img-right/card-img-right.component";

class InfoCardsRow extends React.Component {
    state = CARDS_LEFT_DATA;
    info = this.props.info;
    render(){
        return(
            <>
                <div className={"row bg-orange pt-2"}>
                    <div className={"col-12"}>
                        <div className={"row my-5 pb-5 d-none"}>
                            <div className={"col-12 text-center"}>
                                <h1 className={"font-white"}>Lorem Ipsum Title</h1>
                            </div>
                        </div>
                        <div className={"row my-5 justify-content-center"}>
                            {this.info.cards.map( card => {
                                return(
                                        <CardImgRightComponent title={card.title} text={card.text} image={card.image} buttonText={card.buttonText} buttonLink={card.buttonLink} />
                                )}
                            )}
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default InfoCardsRow