import imageCareCenter from "../../img/hcc/location.png";


const LOCATION_DATA = {
    breadcrumb: {
        items: [
            {
                id: 1,
                linkUrl: "/location",
                title: "Location",
            }
        ]
    },
    page: {
        title: "Optum HCC - Location",
    },
    highlightTitle: {
        title: "Your Optum Health Care Center",
        subtitle: "",
        highlight: {
            title: "Location",
            subtitle: "Visit us",
            buttonText: "Call us",
            buttonLink: "tel:+15624939581",
            image: imageCareCenter,
            location: true,
            locationPatch: true,
        },
    },
}

export default LOCATION_DATA;
