const LOCATIONS_DATA = {
    sections: [
            {
                id: 1,
                title: 'Optum Health Care Center',
                address1: '1661 Golden Rain Rd.',
                address2: 'Seal Beach, CA 90740',
                phone: '1-562-493-9581',
                hours: 'Mon-Fri 8:00AM - 5:00PM',
                notes: '',
                region: '',
                type: ''
            },
        {
            id: 2,
            title: 'Optum Seal Beach Village',
            address1: '13930 Seal Beach Blvd.',
            address2: 'Seal Beach, CA 90740',
            phone: '1-562-493-9581',
            hours: 'Mon-Fri 8:00AM - 5:00PM',
            notes: '',
            region: '',
            type: ''
        },

       /* {
            title: 'Optum - Covina Main',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=4750%20W%20Oakey%20Blvd.%2C%20STE.%202B%20Las%20Vegas%2C%20NV%2089012&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps/place/4750+W+Oakey+Blvd+%232b,+Las+Vegas,+NV+89102/@36.1522567,-115.2086582,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c143e13d9377:0x4af23471357d34ba!8m2!3d36.1522567!4d-115.2064695',
            address1: '420 W. Rowland St.',
            address2: 'Covina, CA 91723',
            address3: 'Located inside the Southwest Medical building, on the 2nd floor.',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8877',
            fax: '1-702-878-3952',
            services:'Elbow, General orthopedic care, Hip, Joints, Knee, Pelvis, Shoulder, Spine, Sports medicine, Trauma',
            id: 1,
            region: '',
        },
        {
            title: 'Oakey Healthcare Center',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=4750%20W%20Oakey%20Blvd.%2C%20STE.%202B%20Las%20Vegas%2C%20NV%2089012&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps/place/4750+W+Oakey+Blvd+%232b,+Las+Vegas,+NV+89102/@36.1522567,-115.2086582,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c143e13d9377:0x4af23471357d34ba!8m2!3d36.1522567!4d-115.2064695',
            address1: '4750 W. Oakey Blvd., Ste 2B',
            address2: 'Las Vegas, NV 89012',
            address3: 'Located inside the Southwest Medical building, on the 2nd floor.',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8877',
            fax: '1-702-878-3952',
            services:'Elbow, General orthopedic care, Hip, Joints, Knee, Pelvis, Shoulder, Spine, Sports medicine, Trauma',
            id: 1
        },
        {
            title: 'Optum Care Cancer Center',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJbwQdJenDyIAR4hiNqrAfAlI&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=36.159456,-115.173352&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=2300+W+Charleston+Blvd+Las+Vegas,+NV+89102',
            address1: '2300 W. Charleston Blvd Ste. 2nd floor',
            address2: 'Las Vegas, NV 89102',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8877',
            fax: '1-702-878-3952',
            services:'Orthopedic oncology',
            id: 2
        },
        {
            title: 'Eastern Healthcare Center',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ71Sb4RjFyIARuW27YcbRNLU&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E',
            linkUrl: 'https://www.google.com/maps/place/Southwest+Medical+Eastern+Healthcare+Center/@36.1077501,-115.1228117,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c518e193758b:0x64d713ce69dd508d!8m2!3d36.1077501!4d-115.1206177',
            address1: '4475 S Eastern Ave',
            address2: 'Las Vegas, NV 89119 ',
            address3: 'Located inside the Southwest Medical building, on the 2nd floor.',
            hours: '',
            phone: '1-702-724-8877',
            fax: '1-702-878-3952',
            services:'General orthopedic care',
            id: 3

        }
        */
    ]
}

export default LOCATIONS_DATA;


