
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";

function FormComponent() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [province, setProvince] = useState("");
    const [zip, setZip] = useState("");
    const [email, setEmail] = useState("");
    const [providerUrl, setProviderUrl] = useState("");
    const [message, setMessage] = useState("");
    const [show, setShow] = useState(false);

    let handleSubmit = async (e) => {
        e.preventDefault();
        const scriptURL = ''
        const form = document.forms['callback-form'];
        const response = await fetch(scriptURL, { method: 'POST', mode: 'no-cors', body: new FormData(form)})
                .then(response => {
                        console.log('Success!', response);
                        setFirstName("");
                        setLastName("");
                        setDob("");
                        setProvince("");
                        setZip("");
                        setProviderUrl("");
                        setMessage("Thanks for your submission, our specialists will contact you within 72 hours.");
                        setShow(true);
                    }
                )
                .catch(error => console.error('Error!', error.message))
    };

    return (
        <>
          <Alert show={show} variant="info">
        <p>
            Thanks for your submission.
        </p>
      </Alert>

         <div className={"row my-5 mx-3"} id={""}>
            <div className={"col-12 text-center"}>
                <h1 className="mb-3">Sign up for important health notifications, including health and wellness tips</h1>
                <h5 className={"mb-5 mx-5"}>By clicking submit, I agree to receive notification emails from Optum
                                regarding my health including reminders, services, wellness tips or
                                products available to me. You understand that these emails containing
                                protected health information will be unencrypted, and there is some risk
                                of interception.
                                To opt out at a later time, visit: <a href={"https://optumcare.com/optout"}>optumcare.com/optout</a></h5>
            </div>
            <div className="col-12 justify-content-around">
                 <form className={"form-group"} onSubmit={handleSubmit} name={"callback-form"} id={"callback-form"}>
                    <div className={"row mx-2"}>
                    <div className={"col-4"}>
                        <label htmlFor="firstname"><h5><b>First Name <span style={{color: "crimson"}}>*</span></b></h5></label>
                        <input
                            required
                            type="text"
                            id={"firstname"}
                            name={"firstname"}
                            value={firstName}
                            className={"form-control mb-3"}
                            placeholder="First Name"
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className={"col-4"}>
                        <label htmlFor="lastname"><h5><b>Last Name <span style={{color: "crimson"}}>*</span></b></h5></label>
                        <input
                            required
                            type="text"
                            id={"lastname"}
                            name={"lastname"}
                            value={lastName}
                            className={"form-control mb-3"}
                            placeholder="Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className={"col-4"}>
                        <label htmlFor="email"><h5><b>Email <span style={{color: "crimson"}}>*</span></b></h5></label>
                        <input
                            required
                            type="email"
                            id={"email"}
                            name={"email"}
                            value={email}
                            className={"form-control mb-3"}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <small className="mt-0">anyname@domain.com</small>
                    </div>
                    <div className={"col-4"}>
                        <label htmlFor="dob"><h5><b>Date of Birth <span style={{color: "crimson"}}>*</span></b></h5></label>
                        <input
                            required
                            type="date"
                            value={dob}
                            id={"dob"}
                            name={"dob"}
                            placeholder="Date of Birth"
                            className={"form-control mb-2"}
                            onChange={(e) => setDob(e.target.value)}
                        />
                        <input
                            type="hidden"
                            value={window.location.pathname}
                            id={"url"}
                            name={"url"}
                        />
                    </div>
                    <div className={"col-4"}>
                        <label htmlFor="province"><h5><b>State <span style={{color: "crimson"}}>*</span></b></h5></label>
                        <select
                            value={province}
                            id={"province"}
                            name={"province"}
                            placeholder="State"
                            className={"form-control mb-2"}
                            onChange={(e) => setProvince(e.target.value)}
                        >
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>
                    <div className={"col-4"}>
                        <label htmlFor="zip"><h5><b>Zip code <span style={{color: "crimson"}}>*</span></b></h5></label>
                        <input
                            required
                            type="text"
                            value={zip}
                            id={"zip"}
                            name={"zip"}
                            placeholder="Zip code"
                            className={"form-control mb-2"}
                            onChange={(e) => setZip(e.target.value)}
                        />
                    </div>
                    </div>
                    <div className={"row mt-5"}>
                        <div className={"col-12 text-center"}>
                            <h5>Fields marked with an asterisk * are required.</h5>
                            <button className={'btn'} type="submit">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
       

    );
}

export default FormComponent;