import backgroundImage from '../../img/placeholders/hero-example.png';

const SPECIALTY_DATA = {
    breadcrumb: {
        items: [
            {
                id: 1,
                linkUrl: "/",
                title: "Care services",
            },
            {
                id: 2,
                linkUrl: "#",
                title: "Specialty care",
            }
        ]
    },
    page: {
        title: "Optum - Specialty care"
    },
    hero: {
        backgroundImg: backgroundImage,
        title: "Specialty care",
        text: "The HCC is a full-service clinic with all your health care needs in mind.",
    },
    buttonsLight: {
        row1: [
            {
                id: 1,
                title: 'Cardiology',
                buttonLight: true,
                onRowSize: "2"
            },
            {
                id: 2,
                title: 'Chiropractic services',
                buttonLight: true,
                onRowSize: "3"
    
            },
            {
                id: 3,
                title: 'Dermatology',
                buttonLight: true,
                onRowSize: "2" 
            },
            {
                id: 4,
                title: 'Dietician',
                buttonLight: true,
                onRowSize: "2"
            },
            {
                id: 5,
                title: 'Endocrinology',
                buttonLight: true,
                onRowSize: "2"
            },
        ],
        row2: [
            {
                id: 6,
                title: 'Gastroenterology',
                buttonLight: true,
                onRowSize: "2",
            },
            {
                id: 7,
                title: 'Massage therapy',
                buttonLight: true,
                onRowSize: "2",
            },
            {
                id: 8,
                title: 'Mental health',
                buttonLight: true,
                onRowSize: "2",
            },
            {
                id: 9,
                title: 'Nephrology',
                buttonLight: true,
                onRowSize: "2",
            },
            {
                id: 10,
                title: 'Optometry and ophthalmology',
                buttonLight: true,
                onRowSize: "3",
            },
        ],
        row3: [
            {
                id: 11,
                title: 'Orthopedics',
                buttonLight: true,
                onRowSize: "2",
            },
            {
                id: 12,
                title: 'Pain management',
                buttonLight: true,
                onRowSize: "2",
            },
        {
            id: 13,
            title: 'Podiatry',
            buttonLight: true,
            onRowSize: "2",
        },
        {
            id: 14,
            title: 'Pulmonology',
            buttonLight: true,
            onRowSize: "2",
        },
        {
            id: 15,
            title: 'Rheumatology',
            buttonLight: true,
            onRowSize: "2",
        },
        {
            id: 16,
            title: 'Wound care',
            buttonLight: true,
            onRowSize: "2",
        },
        ] 
    },
        
    
    infoRows: [
        {
            id: 1,
            title: 'Cardiology',
            text: 'Our cardiologist treats diseases of the heart and blood vessels. Some of the most common diseases we treat are heart failure, high blood pressure and heart rhythm disorders. The cardiologist will run tests, talk about your current health and lifestyle and create a plan that works best for you.',
        },
        {
            id: 2,
            title: 'Chiropractic services',
            text: "Chiropractic work involves body manipulation. This treatment can relieve pain that starts in the muscles, joints and bones. Our licensed chiropractor will perform gentle movements to help realign your spine. Chiropractic treatment is helpful in managing lower back pain, neck pain, headaches and more. ",
        },
        {
            id: 3,
            title: 'Dermatology',
            text: 'Dermatology deals with problems of the skin, hair and nails. Some conditions include fungal infections, psoriasis, eczema and skin cancer. Our dermatologist will do a full-body check to diagnose any skin-related condition. Then they’ll provide treatment options for the specific skin concern.',
        },
        {
            id: 4,
            title: 'Dietician',
            text: "Nutrition is an important part of your complete health. When you’re eating right, it can help you manage a chronic condition better, and may even prevent certain illnesses. Our dietician will get to understand your health history and lifestyle. Then they’ll put together a meal plan with the best types of foods to support your health. ",
        },
        {
            id: 5,
            title: 'Endocrinology',
            text: "Our endocrinologist treats conditions that affect the thyroid, bone health and pancreas, most commonly diabetes. Diabetes is caused by high blood sugar levels. Sometimes conditions can be managed with diet and lifestyle. Other times, medication may be needed. Our specialist can find the root cause of your health concern and recommend a personalized treatment plan.",
        },
        {
            id: 6,
            title: 'Gastroenterology',
            text: "Gastroenterology is a specialty for issues in the digestive system. This can include GERD, IBS, pancreatitis, gallstones, ulcers and other conditions. Our specialist will examine and perform tests to find the cause of your discomfort. Then they’ll recommend treatment to manage or, in some cases, cure your condition.",
        },
        {
            id: 7,
            title: 'Massage therapy',
            text: "Our certified massage therapist provides specific techniques and pressure during treatment. This therapy can reduce back pain and help muscles to relax. Massage therapy is available on Tuesdays and Thursdays with Dr. Oakley. The cost for a 30-minute massage is $35, and $65 for a 60-minute massage.",
        },
        {
            id: 8,
            title: 'Mental health',
            text: "Having good mental health has a positive effect on your overall well-being. Our specialist helps patients manage conditions like anxiety, depression and other mental illnesses. They can recommend the right therapeutic options to improve your mental health. This means a better outlook and quality of life.",
        },
        {
            id: 9,
            title: 'Nephrology',
            text: "Nephrology deals with diagnosis and treatment of conditions affecting the kidney. This most commonly includes chronic kidney disease, infections and growths. Sometimes kidney issues happen from other conditions like diabetes and high blood pressure. Our nephrologist can provide the guidance you need to manage your condition successfully.",
        },
        {
            id: 10,
            title: 'Optometry and ophthalmology',
            text: "Our specialists can diagnose and treat all eye-related conditions. We have both an optometrist and ophthalmologist on staff to help for each unique concern. From vision checks to treatment of eye disease, our team is focused on maintaining your vision intact to keep you healthy and independent.",
        },
        {
            id: 11,
            title: 'Orthopedics',
            text: "Orthopedics deals with conditions of the musculoskeletal system. This includes problems with bones, muscles, joints, nerves, ligaments and tendons. Our orthopedic specialist will work with you to find out the cause of your pain and discomfort. Their goal is to help you regain strength, improve movement and reduce pain.",
        },
        {
            id: 12,
            title: 'Pain management',
            text: "Chronic pain affects millions of Americans. This type of pain can make it hard to perform normal daily tasks. Our pain management specialist will first talk to you about your pain and your current treatment. Together, you’ll decide if the current option or an alternative treatment option is best to manage your pain.",
        },
        {
            id: 13,
            title: 'Podiatry',
            text: "Podiatry is a specialty that focuses on the feet but can include the ankles and legs. Our podiatrist commonly treats foot pain or deformities, and nerve damage from conditions like diabetes. You’ll have an examination and likely some testing done to diagnose the condition. Then the podiatrist can create a care plan with the goal of keeping you active and independent.",
        },
        {
            id: 14,
            title: 'Pulmonology',
            text: "Our pulmonologist treats disease of the respiratory system, mainly affecting the lungs. Some common conditions we see are COPD, asthma, tuberculosis and infections. Our specialist will help you find the best way to manage your condition. The goal is to help you reduce discomfort and breathe easier.",
        },
        {
            id: 15,
            title: 'Rheumatology',
            text: "Rheumatology is a specialty to treat certain problems with connective tissues. The most common diseases are arthritis, lupus, vasculitis and other autoimmune conditions. Our rheumatologist can help you understand your condition. Then they’ll provide the best treatment plan to help you manage it and live well.",
        },
        {
            id: 16,
            title: 'Wound care',
            text: "Certain conditions can make the skin weaker and lead to open wounds. It’s important to seek care for wounds so that they don’t become more complex or infected. Our specialist will care for wounds by examining them and providing the treatment needed to promote healing and reduce pain.",
        },
    ]
}

export default SPECIALTY_DATA;
