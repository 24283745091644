import React from 'react';
import './button.styles.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const ButtonComponent = ({title, buttonLight, buttonWhite, buttonLink, isDownload, id}) => {
    return (
        <a href={buttonLink} className={buttonLight ? "btn btn-light text-nowrap" : (buttonWhite ? "btn btn-white text-nowrap" :"btn optum-btn")}>
            {buttonLight ? (
                <span className={"font-body-2 font-white"}><b>{title}</b></span>
                
            ) : (
                <div className={"row"}>
                    <div className={"col-12 justify-content-center"}>
                        <span className={"font-body-2 font-white"}><b>{title}</b></span>
                    </div>
                </div>
            )}
        </a>
    )
}

export default ButtonComponent