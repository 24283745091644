import React from 'react';
import CardComponent from "./card/card.component";
import CardOverlayComponent from "./card-overlay/card-overlay.component";

const CardsCol = ({data: cards}) => {
        return (
            <>
                {cards.cards.map(card => {
                    return(
                            card.imgOverlay ? (
                                <CardOverlayComponent inRowSize={card.inRowSize} title={card.title} text={card.text} image={card.image} linkText={card.linkText} />
                            ) : (
                                <CardComponent inRowSize={card.inRowSize} noBackground={card.noBackground} title={card.title} text={card.text} image={card.image} linkText={card.linkText} linkUrl={card.linkUrl} isFooter={card.isFooter} />
                            )
                    )
                })}
            </>
        )
}

export default CardsCol