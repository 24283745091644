import imgUrl from '../../img/default.png';

const PROVIDERS_DATA = {
    sections: [
        {
            id: 1,
            imageUrl: imgUrl,
            providerInteriorImage: imgUrl,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [1],
            linkUrl: 'adrian-tripp',
            publicationsAndResearch: [],
            providerName: "Adrian Tripp",
            providerTitle: "MD",
            npi: 1841619277,
            specialty: ["Internal Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Southern California, Keck School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'LAC + USC Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'LAC + USC Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Covina Main",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
        {
            id: 2,
            imageUrl: imgUrl,
            providerInteriorImage: imgUrl,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [2],
            linkUrl: 'lorindha-argudo',
            publicationsAndResearch: [],
            providerName: "Lorindha Francisca Argudo",
            providerTitle: "MD",
            npi: 1750477824,
            specialty: ["Internal Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Indonesian", "Mandarin", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Lorindha Argudo, MD, practices internal medicine. Dr. Argudo earned her medical degree from Ohio State University College of Medicine. She completed her internship and residency at LA County-USC Medical Center. Dr. Argudo has a diverse cultural background and enjoys seeing patients of all ages and from all walks of life. She relates especially well to patients who speak English as a second language. Patients describe her as calm and professional. In her spare time, Dr. Argudo enjoys cooking and gardening. In addition to English, she speaks Mandarin and Indonesian. In addition, she has a basic understanding of Spanish medical terms.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ohio State University Medical School'
                },
                {
                    type: 'Residency',
                    name: 'LAC + USC Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'LAC + USC Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Diamond Bar",
            location2: "Optum - Covina Main",
            region: ["San Gabriel Valley"]
        },
        {
            id: 3,
            imageUrl: imgUrl, //using existing image as examples
            providerInteriorImage: imgUrl,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [3],
            linkUrl: 'marc-ghabbour',
            publicationsAndResearch: [],
            providerName: "Marc Ghabbour",
            providerTitle: "MD",
            npi: 1154702157,
            specialty: ["Internal Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Arabic", "French"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Marc Ghabbour, MD, is board certified in internal medicine. Dr. Ghabbour earned his medical degree from Ross University of Medicine in Miramar, Florida. He completed his internship and residency at Florida Atlantic University in Boca Raton, Florida. An approachable and empathetic problem solver, Dr. Ghabbour provides each of his patients with the tools they need to make informed decisions about their health. His passion for helping people live their healthiest lives motivated him to pursue a career in medicine. Dr. Ghabbour strongly believes in treating every patient like a friend or family member. When he's not caring for his patients, Dr. Ghabbour enjoys boating, traveling, gardening, and spending time with family. In addition to English, Dr. Ghabbour also speaks Arabic and French.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ross University School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Florida Atlantic University'
                },
                {
                    type: 'Internship',
                    name: 'Florida Atlantic University'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Glendora Foothill",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
        {
            id: 4,
            imageUrl: imgUrl, //using existing image as examples
            providerInteriorImage: imgUrl,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [4],
            linkUrl: 'danny-lock',
            publicationsAndResearch: [],
            providerName: "Danny  Lock",
            providerTitle: "MD",
            npi: 1144280348,
            specialty: ["Family Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Danny Lock, MD, is board certified in family medicine. Dr. Lock earned his medical degree from the Universidad Autonoma de Guadalajara School of Medicine in Mexico. He completed two internships: one at LA County-USC Medical Center, and another at UC Irvine Medical Center. He also completed a residency through the USC Family Medicine Residency Program. Dr. Lock really cares about families; that is why he chose family medicine as his medical specialty. He sees a wide variety of patients from children to seniors, and gives them each the attention and time they need. In his spare time, Dr. Lock enjoys spending time with his family and friends. In addition to English, he speaks fluent Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Autonomous University of Guadalajara'
                },
                {
                    type: 'Residency',
                    name: 'USC CA Medical Center/Family Practice'
                },
                {
                    type: 'Internship',
                    name: 'LAC + USC Medical Center; University of California, Irvine Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - La Verne",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
        {
            id: 5,
            imageUrl: imgUrl,
            providerInteriorImage: imgUrl,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [5],
            linkUrl: 'thanda-zaw',
            publicationsAndResearch: [],
            providerName: "Thanda  Zaw",
            providerTitle: "MD",
            npi: 1235491838,
            specialty: ["Internal Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Burmese"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Thanda Zaw, MD, is board certified in internal medicine. Dr. Zaw earned her medical degree from the University of Medicine Yangon in Myanmar. She completed her internship at Yangon General Hospital in Myanmar. She completed her residency at St. Barnabas Hospital in New York. Dr. Zaw entered the field of medicine because it combined her talent as a problem solver with her passion for helping others. She puts her patients' health and happiness first and aims to educate them throughout her practice. Dr. Zaw strives to help patients to make informed decisions as they navigate the health care system. She equips her patients with knowledge and guides patients so they can work together to detect health concerns as early as possible. Dr. Zaw strongly believes in preventive medicine and individualized care. She treats each patient as if they were a close friend or family member. When she's not caring for her patients, Dr. Zaw enjoys time with family and loves traveling, gardening and cooking new recipes. In addition to English, Dr. Zaw speaks Burmese.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Institute of Medicine, Yangon'
                },
                {
                    type: 'Residency',
                    name: 'St. Barnabas Hospital'
                },
                {
                    type: 'Internship',
                    name: 'Yangon General Hospital'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Pasadena Main",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
        {
            id: 6,
            imageUrl: imgUrl,
            providerInteriorImage: imgUrl,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [6],
            linkUrl: 'nicole-cheung',
            publicationsAndResearch: [],
            providerName: "Nicole  Cheung",
            providerTitle: "DO",
            npi: 1164862728,
            specialty: ["Family Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Nicole Cheung, DO, is board certified in family medicine. Dr. Cheung earned her medical degree from Western University of Health Sciences in Pomona. She completed her internship and residency at Arrowhead Regional Medical Center in California. As a family physician, Dr. Cheung diagnoses and treats a wide variety of medical conditions and sees patients of all ages. She believes in a holistic approach to medicine, involving and educating her patients every step of the way. In her spare time, Dr. Cheung enjoys rock climbing and spending time with her daughter. In addition to English, she speaks basic Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Temple City",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
        {
            id: 7,
            imageUrl: imgUrl,
            providerInteriorImage: imgUrl,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [7],
            linkUrl: 'mandeep-kaur',
            publicationsAndResearch: [],
            providerName: "Mandeep Kaur Singh",
            providerTitle: "MD",
            npi: 1386176352,
            specialty: ["Family Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Hindi", "Punjabi"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Government Medical College'
                },
                {
                    type: 'Residency',
                    name: 'The Wright Center for GME - Scranton Temple Residency Program'
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Arcadia",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
        {
            id: 8,
            imageUrl: imgUrl, //using existing image as examples
            providerInteriorImage: imgUrl,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [8],
            linkUrl: 'aleen-avakian',
            publicationsAndResearch: [],
            providerName: "Aleen Avakian",
            providerTitle: "MD",
            npi: 1679860266,
            specialty: ["Family Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Arabic", "Armenian"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Aleen Avakian, MD, is board certified in family medicine. Dr. Avakian earned her medical degree from the University of Baghdad College of Medicine in Iraq. She completed her residency at Beaumont Hospital in Michigan. Dr. Avakian became a physician because of her motivation to help people achieve the best health possible. Her patients say that she is a compassionate physician who always takes the time to answer their questions and addresses their concerns. She recommends that her patients eat healthy, exercise regularly, socialize often, and reduce stress. In her spare time, Dr. Avakian enjoys spending time with her family and friends. In addition to English, she speaks fluent Armenian and Arabic.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Baghdad'
                },
                {
                    type: 'Residency',
                    name: 'William Beaumont Hospital-Troy'
                },
                {
                    type: 'Internship',
                    name: 'William Beaumont Hospital-Troy'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Glen Oaks",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
       /* {
            id: 9,
            imageUrl: img1376912691, //using existing image as examples
            providerInteriorImage: img1376912691,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [9],
            linkUrl: 'siran-sirop',
            publicationsAndResearch: [],
            providerName: "Siran Sirop",
            providerTitle: "MD",
            npi: 1376912691,
            specialty: ["Family Medicine"],
            phone: "1-844-947-1734",
            languages: ["English", "Arabic", "Armenian"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Siran Sirop, MD, practices family medicine. Dr. Sirop earned her medical degree from Yerevan State Medical University in Armenia. She completed her internship and residency at Glendale Adventist Medical Center. As a child, Dr. Sirop watched her father, a doctor, help sick people become well again. Her dad's dedication to others strongly motivated her to follow in his footsteps. Her patients say they appreciate her good listening skills and taking extra time to answer their questions and concerns. Dr. Sirop believes in educating her patients about their medical conditions and treatment options so they can get better sooner and stay healthy. In her spare time, she enjoys traveling, hiking and spending time with her son. In addition to English, Dr. Sirop speaks Armenian and Arabic.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Yerevan State Medical University'
                },
                {
                    type: 'Residency',
                    name: 'Glendale Adventist Medical Center Family Medicine'
                },
                {
                    type: 'Internship',
                    name: 'Adventist Health Glendale'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Verdugo",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
        {
            id: 10,
            imageUrl: img1538208236, //using existing image as examples
            providerInteriorImage: img1538208236,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [10],
            linkUrl: 'gustavo-estrella',
            publicationsAndResearch: [],
            providerName: "Gustavo Eduardo Estrella",
            providerTitle: "MD",
            npi: 1538208236,
            specialty: ["Family Medicine"],
            phone: "1-844-326-7097",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Gustavo Estrella, MD, is board certified in family medicine. Dr. Estrella earned his medical degree from the University of California San Francisco School of Medicine in San Francisco, California. He completed both his internship and residency at White Memorial Medical Center in Los Angeles, California. Dr. Estrella is passionate about helping people in underserved communities live healthier lives. He listens to each patient's concerns and creates customized care plans to help them reach their health goals. When he's not caring for his patients, Dr. Estrella enjoys spending time with his family, running and watching sports. Dr. Estrella speaks English and Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of California, San Francisco School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'White Memorial Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'White Memorial Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - East Los Angeles",
            location2: " ",
            region: ["LA/Downtown"]
        },
        {
            id: 11,
            imageUrl: img1578084604, //using existing image as examples
            providerInteriorImage: img1578084604,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [11],
            linkUrl: 'julio-hernandez',
            publicationsAndResearch: [],
            providerName: "Julio Ivan Alexander Hernandez Rodriguez",
            providerTitle: "MD",
            npi: 1578084604,
            specialty: ["Family Medicine"],
            phone: "1-844-326-7097",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Julio Ivan Alexander Hernandez Rodriguez, MD, is board certified in family medicine. Dr. Rodriguez earned his medical degree from Dr. Jose Matias Delgado University in El Salvador. He completed his internship and residency at Zucker School of Medicine at Hofstra/Northwell in New York. Dr. Rodriguez takes pride in being a good listener and believes in the power of empathy. Rather than dictating care, he prefers coming up with joint solutions with his patients. His biggest clinical interest is in LGBTQ+ health and equitable access to health care. In his spare time, Dr. Rodriguez enjoys traveling. Prior to joining the Optum family, he visited 11 countries in three years. He loves to be in nature and to go on hikes. He has even climbed the summit of Mt. Kilimanjaro. In addition to English, Dr. Rodriguez speaks fluent Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Dr. Jose Matias Delgado University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'Zucker School of Medicine at Hofstra/Northwell at Mather Hospital'
                },
                {
                    type: 'Internship',
                    name: 'Zucker School of Medicine at Hofstra/Northwell at Mather Hospital'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Los Angeles",
            location2: " ",
            region: ["LA/Downtown"]
        },
        {
            id: 12,
            imageUrl: img1356862684,
            providerInteriorImage: img1356862684,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [11],
            linkUrl: 'sarah-gahng',
            publicationsAndResearch: [],
            providerName: "Sarah  Minhee  Gahng",
            providerTitle: "MD",
            npi: 1356862684,
            specialty: ["Family Medicine"],
            phone: "1-844-326-7097",
            languages: ["English", "Korean", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Sarah Gahng, MD, is board certified in family medicine. Dr. Gahng earned her medical degree from St. George's University in Grenada. She completed her internship and residency at Arrowhead Regional Medical Center in California. Dr. Gahng's passion for medicine is rooted in her belief that the key to optimal health lies in the partnership between a doctor and patient. She believes in empowering patients through education to help inform decisions that lead to a healthier and happier lifestyle. In her free time, Dr. Gahng enjoys spending time with her family and her dog, Leo, traveling and hiking while being involved in her church community. In addition to English, she speaks Spanish and Korean.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'St. George\'s University'
                },
                {
                    type: 'Residency',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Arrowhead Regional Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Los Angeles",
            location2: " ",
            region: ["LA/Downtown"]
        },
        {
            id: 13,
            imageUrl: img1093237786, //using existing image as examples
            providerInteriorImage: img1093237786,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [11],
            linkUrl: 'melvin-rivera',
            publicationsAndResearch: [],
            providerName: "Melvin  Daniel Rivera Carrero",
            providerTitle: "MD",
            npi: 1093237786,
            specialty: ["Internal Medicine"],
            phone: "1-844-326-7097",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Melvin Rivera Carrero, MD, practices internal medicine. Dr. Carrero earned his medical degree from San Juan Bautista School of Medicine in Puerto Rico.  He completed both his internship and residency at Mayaguez Medical Center in Puerto Rico. Dr. Carrero is passionate about forming relationships based on trust and understanding and focuses on using his knowledge and interpersonal skills to help others. Dr. Carrero takes pride in being friendly, approachable and a great listener. He sees preventive care as a priority, as it can greatly improve the quality of his patients' lives. He works to provide his patients with the tools and knowledge that will empower them to make informed decisions and encourages them to actively participate in reaching their health goals. When he's not caring for his patients, Dr. Carrero enjoys the outdoors and sharing quality time with his wife, two daughters and their dog. Their time together usually revolves around forging meaningful connections with nature. Some of their favorite activities to do together are hiking trails, surfing at the beach, skateboarding, swimming and building sandcastles by the sea. In addition to English, Dr. Carrero speaks fluent Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'San Juan Bautista School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Mayagüez Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Mayagüez Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Los Angeles",
            location2: " ",
            region: ["LA/Downtown"]
        },
        {
            id: 14,
            imageUrl: img1821027699,
            providerInteriorImage: img1821027699,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [12],
            linkUrl: 'lilia-zamora',
            publicationsAndResearch: [],
            providerName: "Lilia V. Zamora",
            providerTitle: "MD",
            npi: 1821027699,
            specialty: ["Family Medicine"],
            phone: "1-844-326-7097",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Lilia Zamora, MD, practices family medicine. Dr. Zamora earned her medical degree from the UC Davis School of Medicine. Dr. Zamora completed her residency and a fellowship in obstetrics and gynecology at White Memorial Medical Center in Los Angeles. She prides herself on spending as much time as possible with her patients. In addition to English, Dr. Zamora speaks Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'UC Davis, School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'White Memorial Medical Center'
                },
                {
                    type: 'Internship',
                    name: ' '
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Montebello",
            location2: " ",
            region: ["LA/Downtown"]
        },
        {
            id: 15,
            imageUrl: img1922137173,
            providerInteriorImage: img1922137173,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [13],
            linkUrl: 'maria-garcia-sioson',
            publicationsAndResearch: [],
            providerName: "Maria Virginia Garcia Sioson-Ayala",
            providerTitle: "MD",
            npi: 1922137173,
            specialty: ["Family Medicine"],
            phone: "1-844-326-7097",
            languages: ["English", "Tagalog"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Maria-Virginia Garcia Sioson-Ayala, MD, is board certified in family medicine. Dr. Sioson-Ayala earned her medical degree from the University of Santo Tomas in the Philippines. She completed her internship and residency at the University of Tennessee Health Center. Dr. Sioson-Ayala became a physician so she could improve her patients' quality of life in the best way possible. She educates them on how to practice a healthy lifestyle with nutritious eating, regular exercise, and stress reduction. In her spare time, she visits new places, listens to music, watches movies, and spends time with her family. In addition to English, Dr. Sioson-Ayala speaks fluent Tagalog and medical Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Santo Tomas Faculty of Medicine & Surgery'
                },
                {
                    type: 'Residency',
                    name: 'University of Tennessee - Jackson Program'
                },
                {
                    type: 'Internship',
                    name: 'University of Tennessee - Jackson Program'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Monterey Park",
            location2: "Optum - East Los Angeles",
            region: ["LA/Downtown"]
        },
        {
            id: 16,
            imageUrl: img1396064614,
            providerInteriorImage: img1396064614,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [13],
            linkUrl: 'vincent-wu',
            publicationsAndResearch: [],
            providerName: "Vincent Chun-I Wu",
            providerTitle: "DO",
            npi: 1396064614,
            specialty: ["Family Medicine"],
            phone: "1-844-326-7097",
            languages: ["English", "Mandarin", "Taiwanese"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Vincent Wu, DO, is board certified in family medicine. Dr. Wu earned his medical degree from Western University of Health Sciences, College of Osteopathic Medicine in Pomona. He completed his internship and residency at UCI Medical Center in Irvine. Dr. Wu had a physician mentor who urged him to create special relationships with patients. Inspired by his mentor, Dr. Wu strives to not only diagnose and treat his patients in the best way possible, but also to build trust and rapport. To put them at ease, Dr. Wu sometimes jokes with his patients and engages in small talk. Then he discusses their health issues in detail. He always encourages them to eat nutritious foods, exercise regularly and reduce stress for optimum health. In his spare time, Dr. Wu enjoys spending time with his family, participating in church activities and reading. In addition to English, he speaks Mandarin and Taiwanese.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'University of California, Irvine'
                },
                {
                    type: 'Internship',
                    name: 'University of California, Irvine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Monterey Park",
            location2: " ",
            region: ["LA/Downtown"]
        },
        {
            id: 17,
            imageUrl: img1699295758, //using existing image as examples
            providerInteriorImage: img1699295758,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [14],
            linkUrl: 'feliks-avanesyan',
            publicationsAndResearch: [],
            providerName: "Feliks Avanesyan",
            providerTitle: "MD",
            npi: 1699295758,
            specialty: ["Family Medicine"],
            phone: "1-844-326-7097",
            languages: ["English", "Armenian"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Feliks Avanesyan, MD, practices family medicine. Dr. Avanesyan earned his medical degree from St. George's University in Grenada. He completed his internship and residency at the Southern Illinois University in Illinois. Dr. Avanesyan's passion for medicine is rooted in his belief that the partnership between patient and doctor is part of the key to good health. He believes strongly that the patient is the primary decision maker and the role of the doctor is to provide an understanding of the human body to help inform sound decision-making. Dr. Avanesyan enjoys hiking, classical piano and learning foreign lanaguages in his free time. In addition to English, he speaks fluent Armenian.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'St. George\'s University'
                },
                {
                    type: 'Residency',
                    name: 'Southern Illinois University'
                },
                {
                    type: 'Internship',
                    name: 'Southern Illinois University'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Descanso",
            location2: "Optum - Glendale",
            region: ["LA/Downtown"]
        },
        {
            id: 18,
            imageUrl: img1922479930,
            providerInteriorImage: img1922479930,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [15],
            linkUrl: 'katarzyna-nasiadko',
            publicationsAndResearch: [],
            providerName: "Katarzyna Natalia Nasiadko Albuquerque",
            providerTitle: "MD",
            npi: 1922479930,
            specialty: ["Family Medicine"],
            phone: "1-855-464-6505",
            languages: ["English", "Polish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Katarzyna Nasiadko, MD, practices family medicine. Dr. Nasiadko earned her medical degree from the American University of Antigua, College of Medicine in New York. She completed her internship and residency through the Western Reserve Care System in Ohio. Dr. Nasiadko chose primary care medicine because of the opportunity to build trusting relationships with patients and their families. Patients give Dr. Nasiadko high marks for her warm, caring manner and her open-minded position on treatment options. She is a strong believer in preventive care and encourages her patients to take care of health by eating nutritious meals, exercising regularly, reducing stress and having a supportive social life. In her spare time, Dr. Nasiadko enjoys traveling, learning about new cultures, practicing yoga, going to the beach and spending time with her dogs. In addition to English, she speaks Polish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'American University of Antigua'
                },
                {
                    type: 'Residency',
                    name: 'Western Reserve Care System'
                },
                {
                    type: 'Internship',
                    name: 'Western Reserve Health Education Inc.'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Northridge",
            location2: " ",
            region: ["San Fernando Valley"]
        },
        {
            id: 19,
            imageUrl: img1235164971, //using existing image as examples
            providerInteriorImage: img1235164971,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [16],
            linkUrl: 'alwin-carl',
            publicationsAndResearch: [],
            providerName: "Alwin Carl Lewis",
            providerTitle: "MD",
            npi: 1235164971,
            specialty: ["Internal Medicine"],
            phone: "1-855-464-6505",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Alwin Lewis, MD, is board certified in internal medicine. Dr. Lewis earned his medical degree from Tulane University in New Orleans. He completed his internship and residency at the University of California, Irvine. Dr. Lewis is proud to help improve his patient's state of health through behavior modification and disease prevention, working to reduce medication and increase optimal levels of health. He hopes his patients can appreciate his genuine passion he has for improving their health and believes his job as their doctor is to better each patient's quality of life. Dr. Lewis is an accomplished pianist and plays everyday. In his spare time, he loves to play golf and tennis with his two sons and his wife, who is quite the athlete.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Tulane University'
                },
                {
                    type: 'Residency',
                    name: 'University of California, Irvine'
                },
                {
                    type: 'Internship',
                    name: 'University of California, Irvine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Burbank",
            location2: " ",
            region: ["San Fernando Valley"]
        },
        {
            id: 20,
            imageUrl: img1902816903,
            providerInteriorImage: img1902816903,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [17],
            linkUrl: 'taura-michelle',
            publicationsAndResearch: [],
            providerName: "Taura Michelle Olfus",
            providerTitle: "DO",
            npi: 1902816903,
            specialty: ["Family Medicine"],
            phone: "1-855-464-6505",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Taura Olfus, DO, is board certified in family medicine. She earned her medical degree from the New York Institute of Technology College of Osteopathic Medicine in Old Westbury, New York. Dr. Olfus completed her internship at St. Vincent's Midtown Hospital in New York, New York. She completed her residency at Long Beach Medical Center in Long Beach, California. Dr. Olfus is passionate about helping people live healthier lives. She is an open communicator and a good listener. Dr. Olfus strongly believes that the best health outcomes are achieved by working together with her patients. Her clinical interests include diabetes, obesity, heart health and women's health concerns. When she's not caring for her patients, Dr. Olfus enjoys running, traveling and, spending time with her family. Dr. Olfus speaks English and Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'New York College of Osteopathic Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Long Beach Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'St. Vincent\'s Midtown Hospital'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Valencia",
            location2: "Optum - Canyon Country",
            region: ["San Fernando Valley"]
        },
        {
            id: 21,
            imageUrl: img1518995133,
            providerInteriorImage: img1518995133,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [18],
            linkUrl: 'samuel-orgel',
            publicationsAndResearch: [],
            providerName: "Samuel Eduardo Oregel",
            providerTitle: "MD",
            npi: 1518995133,
            specialty: ["Family Medicine"],
            phone: "1-855-464-6505",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Samuel Oregel, MD, is board certified in family medicine. Dr. Oregel earned his medical degree from the University of California, Davis School of Medicine in Sacramento, California. He completed his internship and the California Hospital Medical Center in Los Angeles and his residency at the UC David Medical Center in Sacramento. Dr. Oregel pursued a career in medicine to help and empower his community regarding their health. He believes that patient education is key to helping his patients achieve optimal levels of health and wellness. In his free time, Dr. Oregel enjoys fitness, spending time with his wife and family and sports. In addition to English, he speaks Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'UC Davis'
                },
                {
                    type: 'Residency',
                    name: 'UC Davis, Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'California Hospital Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum -Van Nuys",
            location2: "Optum - Vanowen",
            region: ["San Fernando Valley"]
        },
        {
            id: 22,
            imageUrl: img1104245000,
            providerInteriorImage: img1104245000,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [18],
            linkUrl: 'cynthia-pike',
            publicationsAndResearch: [],
            providerName: "Cynthia Rae Pike",
            providerTitle: "MD",
            npi: 1104245000,
            specialty: ["Family Medicine"],
            phone: "1-855-464-6505",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Cynthia Pike, MD, is board certified in family medicine. Dr. Pike earned her medical degree from Texas A & M University in Temple, TX. She completed her internship and residency at UC Irvine Medical Center. Dr. Pike specializes in caring for people of all ages, at all stages of life. She provides patients advice on preventing diseases by getting enough sleep, eating nutritious foods, exercising regularly and reducing stress. In her spare time, Dr. Pike enjoys exploring the Los Angeles area with her husband. In addition to English, she speaks basic Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Texas A&M University'
                },
                {
                    type: 'Residency',
                    name: 'University of California, Irvine'
                },
                {
                    type: 'Internship',
                    name: 'University of California, Irvine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum -Van Nuys",
            location2: " ",
            region: ["San Fernando Valley"]
        },
        {
            id: 23,
            imageUrl: img1932526514,
            providerInteriorImage: img1932526514,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [18],
            linkUrl: 'javier-moncayo',
            publicationsAndResearch: [],
            providerName: "Javier Andres Lopez Moncayo",
            providerTitle: "MD",
            npi: 1932526514,
            specialty: ["Internal Medicine"],
            phone: "1-855-464-6505",
            languages: ["English", "Portuguese", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Javier Lopez Moncayo, MD, is board certified in internal medicine. Dr. Lopez Moncayo earned his medical degree from Catholica University of Santiago Guayaquil in Ecuador. He completed his internship and residency at Seton Hall University-St. Francis Medical Center in New Jersey. Dr. Lopez Moncayo chose internal medicine with a focus on geriatrics because of the profound difference he can make in helping older people achieve their best possible health, even later in life. Patients give him high marks for the extra time he takes to address their questions and address concerns. They also say he is empathetic, patient and involves them in making decisions about their treatment options. In his spare time, Dr. Lopez Moncayo enjoys working out at the gym, running, listening to music and watching comedy on Netflix. In addition to English, he speaks Spanish and Portuguese.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Catholic University of Santiago De Guayaquil'
                },
                {
                    type: 'Residency',
                    name: 'Seton Hall University - St. Francis Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Seton Hall University - St. Francis Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum -Van Nuys",
            location2: " ",
            region: ["San Fernando Valley"]
        },
        {
            id: 24,
            imageUrl: img1407350622,
            providerInteriorImage: img1407350622,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [19],
            linkUrl: 'ashton-garbutt',
            publicationsAndResearch: [],
            providerName: "Ashton Mark Garbutt",
            providerTitle: "MD",
            npi: 1407350622,
            specialty: ["Family Medicine"],
            phone: "1-855-464-6505",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Ashton Garbutt, MD, is board certified in family medicine. Dr. Garbutt earned his medical degree from Albany Medical College in New York. He completed his internship and residency at Dignity Health Medical Foundation in Northridge, California. Dr. Garbutt believes in helping people lead healthier lives. He was drawn to medicine because he enjoys solving complex problems and is passionate about helping others. Dr. Garbutt focuses on treating every patient as if they were a family member or close friend. He puts patients first by striving to be an active listener who is approachable, empathetic, and friendly. His clinical interests include diabetes, geriatric health, heart health, and pediatric care. When he's not caring for his patients, Dr. Garbutt enjoys cooking, reading, and spending time with family and friends. He also loves to play and watch basketball.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Albany Medical College'
                },
                {
                    type: 'Residency',
                    name: 'Dignity Health Medical Foundation'
                },
                {
                    type: 'Internship',
                    name: 'Dignity Health Medical Foundation'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - West Hills",
            location2: " ",
            region: ["San Fernando Valley"]
        },
        {
            id: 25,
            imageUrl: img1578097275,
            providerInteriorImage: img1578097275,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [20],
            linkUrl: 'andrew-yen',
            publicationsAndResearch: [],
            providerName: "Andrew Taylor Yen",
            providerTitle: "DO",
            npi: 1578097275,
            specialty: ["Family Medicine"],
            phone: "1-844-947-1734",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Andrew Yen, DO, is board certified in family medicine. Dr. Yen earned his medical degree from the Western University of Health Sciences in California. He completed his internship and residency at Mercy Medical Center-Redding in California. Dr. Yen became a doctor to help improve the health of others. He pursued family medicine, specifically, as he enjoys building relationships with his patients over time. Dr. Yen believes in creating both short and long-term goals for his patients. He has found that this helps his patients' chronic conditions to become more manageable, while also helping his patients to be more actively involved in their own health. Dr. Yen enjoys traveling with his wife, running and watching sports. In addition to English, he speaks medical Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'Mercy Medical Center Redding'
                },
                {
                    type: 'Internship',
                    name: 'Mercy Medical Center Redding'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - San Gabriel",
            location2: " ",
            region: ["San Gabriel Valley"]
        },
        {
            id: 26,
            imageUrl: img1689193336,
            providerInteriorImage: img1689193336,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [22],
            linkUrl: 'muhammad-nouh',
            publicationsAndResearch: [],
            providerName: "Muhammad Mahmoud Nouh ",
            providerTitle: "DO",
            npi: 1689193336,
            specialty: ["Family Medicine"],
            phone: "1-866-488-5203",
            languages: ["English", "Spanish", "Arabic"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Muhammad Nouh, DO, is board certified in family medicine. Dr. Nouh earned his medical degree from Touro College of Osteopathic Medicine in New York. He completed his internship and residency at College Medical Center in Long Beach. Dr. Nouh's goal is to help his patients and their families understand the importance of good health. He believes strongly in the adage of \"An ounce of prevention is worth a pound of cure\". He enjoys educating his patients on preventive medicine and develop care plans that they are comfortable with. In his spare time, Dr. Nouh enjoys weight-lifting, swimming, driving and racing cars, video gaming, and spending time with family and friends. In addition to English, he speaks basic Arabic and Spanish.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Touro College of Osteopathic Medicine'
                },
                {
                    type: 'Residency',
                    name: 'College Medical Center in Long Beach'
                },
                {
                    type: 'Internship',
                    name: 'College Medical Center in Long Beach'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Spring Street",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 27,
            imageUrl: img1700248879,
            providerInteriorImage: img1700248879,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [23],
            linkUrl: 'haivy-nguyen',
            publicationsAndResearch: [],
            providerName: "Haivy Thai Nguyen",
            providerTitle: "MD",
            npi: 1700248879,
            specialty: ["Internal Medicine"],
            phone: "1-866-488-5203",
            languages: ["English", "Vietnamese"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Haivy Nguyen, MD, is board certified in internal medicine. Dr. Nguyen earned her medical degree from Sackler School of Medicine in New York. She completed her internship and her residency at Lenox Hill Hospital in New York City. Dr. Nguyen became a doctor with the goal of helping people lead happier and healthier lives. Dr. Nguyen strongly believes in patient education. She strives to provide the most accessible and easy-to-understand information to help patients with their medical conditions so they can make informed decisions about their health and lifestyle habits. Dr. Nguyen prides hierself on being an active listener. She likes to make people smile and laugh, even if it means telling corny jokes. After all, she believes that laughter is the best medicine. When she's not caring for her patients, Dr. Nguyen loves to explore nature and spend time outdoors. She goes camping, hiking, and rock climbing whenever she can. In addition to English, Dr. Nguyen speaks Vietnamese.",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Sackler School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'Lenox HIll Hospital'
                },
                {
                    type: 'Internship',
                    name: 'Lenox HIll Hospital'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Downtown Long Beach",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 28,
            imageUrl: img1306942545,
            providerInteriorImage: img1306942545,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [24],
            linkUrl: 'wail-shoga',
            publicationsAndResearch: [],
            providerName: "Wail A. Shoga",
            providerTitle: "DO",
            npi: 1306942545,
            specialty: ["Internal Medicine"],
            phone: "1-866-488-5203",
            languages: ["English", "Arabic"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Wail Shoga, DO, is board certified in internal medicine. He earned his medical degree from Western University of Health Sciences, College of Osteopathic Medicine of the Pacific in Pomona. He completed his internship and residency at the University of Medicine and Dentistry of New Jersey. Dr. Shoga has a special interest in preventive medicine and health enhancement. In his spare time, he enjoys skiing, cycling and gardening. In addition to English, Dr. Shoga speaks Arabic.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'College of Osteopathic Medicine of the Pacific'
                },
                {
                    type: 'Residency',
                    name: 'University of Medicine & Dentistry of NJ'
                },
                {
                    type: 'Internship',
                    name: 'University of Medicine & Dentistry of NJ'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Airport Plaza",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 29,
            imageUrl: img1265708531,
            providerInteriorImage: img1265708531,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [25],
            linkUrl: 'johana-flores-rios',
            publicationsAndResearch: [],
            providerName: "Johana  Esther Flores-Rios",
            providerTitle: "MD",
            npi: 1265708531,
            specialty: ["Family Medicine"],
            phone: "1-866-488-5203",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Johana Flores-Rios, MD, is board certified in family medicine. Dr. Flores-Rios earned her medical degree from National Autonomous University of Honduras. She completed her internship and residency at Keck-USC School of Medicine. Dr. Flores-Rios sees patients of all ages and backgrounds. In particular, she has a passion to help and heal the underserved. She also has a strong interest in women's health and obstetrics care. Dr. Flores-Rios believes strongly about educating her patients on how improving their health can make a real difference in their lives. She encourages them to practice good habits such as eating nutritious foods, exercising regularly and reducing stress. In her spare time, Dr. Flores-Rios enjoys hiking, dancing to Hispanic music and traveling. In addition to English, she speaks Spanish.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'National Autonomous University of Honduras'
                },
                {
                    type: 'Residency',
                    name: 'USC Family Medicine - Residency Program'
                },
                {
                    type: 'Internship',
                    name: 'USC - Family Medicine Program California Hospital Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Lakewood",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 30,
            imageUrl: img1447505284,
            providerInteriorImage: img1447505284,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [26],
            linkUrl: 'hung-phan',
            publicationsAndResearch: [],
            providerName: "Hung Canh Phan",
            providerTitle: "MD",
            npi: 1447505284,
            specialty: ["Internal Medicine"],
            phone: "1-866-488-5203",
            languages: ["English", "Vietnamese"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Hung Phan, MD, is board certified in internal medicine. Dr. Phan earned his medical degree from Hue University of Medicine and Pharmacy in Vietnam. He completed three internships and three residencies at the following centers: Louis Weiss Memorial Hospital in Chicago; St. Mary's Hospital and Medical Center in San Francisco; and Charite Humbodlt University in Germany. Dr. Phan's patients give him high marks for his thorough exams, great listening skills and expert knowledge. In his spare time, he enjoys playing tennis and spending time with his family and friends. He also enjoys growing and cultivating Bonsai trees. In addition to English, Dr. Phan speaks fluent Vietnamese.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Hue University of Medicine and Pharmacy in Vietnam'
                },
                {
                    type: 'Residency',
                    name: 'Louis Weiss Memorial Hospital in Chicago; St. Mary\'s Hospital and Medical Center in San Francisco; Charite Humboldt University in Germany'
                },
                {
                    type: 'Internship',
                    name: 'Louis Weiss Memorial Hospital in Chicago; St. Mary\'s Hospital and Medical Center in San Francisco; Charite Humboldt University in Germany'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Downey",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 31,
            imageUrl: img1447612361,
            providerInteriorImage: img1447612361,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [26],
            linkUrl: 'kevin-urmaza',
            publicationsAndResearch: [],
            providerName: "Kevin Castro Urmaza",
            providerTitle: "DO",
            npi: 1447612361,
            specialty: ["Family Medicine"],
            phone: "1-866-488-5203",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Kevin Urmaza, DO, is board certified in family medicine. Dr. Urmaza earned his medical degree from the Western University of Health Sciences in California. He completed his internship and residency at the University of Nevada School of Medicine. Dr. Urmaza dedicates himself to his patient relationships and providerTitleits his listening skills to be the key to helping patients live their healthiest lives. He enjoys hiking in the mountains, snowboarding and traveling.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Western University of Health Sciences'
                },
                {
                    type: 'Residency',
                    name: 'University of Nevada School of Medicine'
                },
                {
                    type: 'Internship',
                    name: 'University of Nevada School of Medicine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Downey",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 32,
            imageUrl: img1245736842,
            providerInteriorImage: img1245736842,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [27],
            linkUrl: 'virpal-gill',
            publicationsAndResearch: [],
            providerName: "Virpal Kaur Gill",
            providerTitle: "MD",
            npi: 1245736842,
            specialty: ["Family Medicine"],
            phone: "1-866-488-5203",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Virpal Gill, MD, practices family medicine. Dr. Gill earned her medical degree from Oakland University. She completed her internship and residency at UCSF Fresno. Dr. Gill decided to become a doctor when she went on a public health trip to Tanzania. The experience left her with the desire to help others who were suffering from illnesses that could have easily been prevented through education and compassion. Dr. Gill strongly believes in treating every patient like a family member. She prides herself on being present with each patient through active listening and keeping her patients calm in difficult situations. When she's not caring for her patients, Dr. Gill loves to travel, try new foods, and go on walks with her dog. In addition to English, Dr. Gill speaks Hindi and Punjabi.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Oakland University'
                },
                {
                    type: 'Residency',
                    name: 'UCSF Fresno'
                },
                {
                    type: 'Internship',
                    name: 'UCSF Fresno'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Willow",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 33,
            imageUrl: img1477915452,
            providerInteriorImage: img1477915452,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [28],
            linkUrl: 'jan-nguyen',
            publicationsAndResearch: [],
            providerName: "Jan Thanh Nguyen",
            providerTitle: "DO",
            npi: 1477915452,
            specialty: ["Internal Medicine"],
            phone: "1-866-488-5203",
            languages: ["English", "Vietnamese"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'College of Osteopathic Medicine of the Pacific'
                },
                {
                    type: 'Residency',
                    name: 'University of Washington School of Medicine'
                },
                {
                    type: 'Internship',
                    name: 'University of Washington School of Medicine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Bixby Knolls",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 34,
            imageUrl: img1164421814,
            providerInteriorImage: img1164421814,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [29],
            linkUrl: 'valerie-green',
            publicationsAndResearch: [],
            providerName: "Valerie Renee Green",
            providerTitle: "MD",
            npi: 1164421814,
            specialty: ["Family Medicine"],
            phone: "1-866-488-5203",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Valerie Green, MD, is board certified in family medicine. Dr. Green earned her medical degree from Temple University in Philadelphia. She completed her internship and residency at the University of Maryland Medical Center. Dr. Green decided she wanted to be a doctor at a young age because of her interest in helping to heal others. Patients say that she is an advocate for their health and listens intently to their concerns. In her spare time, Dr. Green enjoys going to the beach, exercising, dancing and spending time with her family. In addition to English, she speaks Spanish.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Temple University'
                },
                {
                    type: 'Residency',
                    name: 'University of Maryland'
                },
                {
                    type: 'Internship',
                    name: 'University of Maryland'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Compton",
            location2: " ",
            region: ["Long Beach"]
        },
        {
            id: 35,
            imageUrl: img1437654811,
            providerInteriorImage: img1437654811,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [30],
            linkUrl: 'jeffrey-pan',
            publicationsAndResearch: [],
            providerName: "Jeffrey Pan",
            providerTitle: "MD",
            npi: 1437654811,
            specialty: ["Internal Medicine"],
            phone: "1-844-958-4189",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Jeffrey Pan, MD, practices internal medicine. Dr. Pan earned his medical degree from St. George's University School of Medicine. He completed both his internship and residency at the University of Hawaii. Dr. Pan decided to pursue medicine because he has a passion for helping people live their healthiest lives. Dr. Pan believes in a strong patient-doctor relationship to help his patients make informed decisions about their health. He treats every patient as if they were his family. Dr. Pan prides himself on being a good listener and strong communicator. He has an empathetic approach and believes in making his patients feel comfortable. When he's not caring for his patients, Dr. Pan loves to hike, read, and spend time with his family. In addition to English, Dr. Pan speaks Mandarin.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'St. George\'s University School of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'University of Hawaii'
                },
                {
                    type: 'Internship',
                    name: 'University of Hawaii'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Del Amo Specialty",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 36,
            imageUrl: img1619408697,
            providerInteriorImage: img1619408697,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [30],
            linkUrl: 'stephanie-brianne',
            publicationsAndResearch: [],
            providerName: "Stephanie Brianne Matza",
            providerTitle: "DO",
            npi: 1619408697,
            specialty: ["Family Medicine"],
            phone: "1-844-958-4189",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Touro University in Nevada'
                },
                {
                    type: 'Residency',
                    name: 'St. Luke\'s Memorial Hospital Center'
                },
                {
                    type: 'Internship',
                    name: 'Eastern Connecticut Health Professionals'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum -Del Amo Specialty",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 37,
            imageUrl: img1932690484,
            providerInteriorImage: img1932690484,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [31],
            linkUrl: 'daniel-placik',
            publicationsAndResearch: [],
            providerName: "Daniel Placik",
            providerTitle: "MD",
            npi: 1932690484,
            specialty: ["Family Medicine"],
            phone: "1-844-958-4189",
            languages: ["English", "Spanish"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Universidad Autonoma de Guadalajara'
                },
                {
                    type: 'Residency',
                    name: 'Yuma Regional Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'Yuma Regional Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Western San Pedro",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 38,
            imageUrl: img1225370679,
            providerInteriorImage: img1225370679,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [32],
            linkUrl: 'keith-kagahastian',
            publicationsAndResearch: [],
            providerName: "Keith Andrew Kagahastian Ebilane",
            providerTitle: "MD",
            npi: 1225370679,
            specialty: ["Internal Medicine"],
            phone: "1-844-958-4189",
            languages: ["English", "Spanish", "Tagalog"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Keith Ebilane, MD, is board certified in internal medicine. Dr. Ebilane earned his medical degree from the University of Santo Tomas Faculty of Medicine and Surgery in the Philippines. He completed his internship and residency at the University of Nevada School of Medicine. Dr. Ebilane chose the medical profession because of his passion to help people optimize their health. He believes strongly in educating his patients so they can make informed decisions about their treatment options. In his spare time, Dr. Ebilane enjoys outdoor activities with his family, especially exploring beaches and parks. In addition to English, he speaks fluent Tagalog.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Santo Tomas'
                },
                {
                    type: 'Residency',
                    name: 'University of Nevada School of Medicine'
                },
                {
                    type: 'Internship',
                    name: 'University of Nevada School of Medicine'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Gardena",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 39,
            imageUrl: img1851753370,
            providerInteriorImage: img1851753370,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [33],
            linkUrl: 'philip-simmons',
            publicationsAndResearch: [],
            providerName: "Philip Anthony Simmons",
            providerTitle: "DO",
            npi: 1851753370,
            specialty: ["Family Medicine"],
            phone: "1-844-958-4189",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Philip Simmons, DO, practices family medicine. Dr. Simmons earned his medical degree from Kirksville College of Osteopathic Medicine in Missouri. He completed his internship and residency at Honor Health Scottsdale Osborn Medical Center in Arizona. As an osteopathic doctor, Dr. Simmons believes in a holistic approach to health care. This means that while he prescribes medication, he also emphasizes positive lifestyle habits to naturally heal the body. These include eating nutritious foods, exercising regularly and reducing stress. Most importantly, Dr. Simmons partners with his patients to help them get healthy and stay that way. Patients give him high marks for his sincere interest in their well-being and lively sense of humor. In his spare time, Dr. Simmons enjoys cycling, hiking and spending time with his wife and dog.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Kirksville College of Osteopathic Medicine in Missouri'
                },
                {
                    type: 'Residency',
                    name: 'Honor Health Osborn'
                },
                {
                    type: 'Internship',
                    name: 'Honor Health Osborn'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - East Carson",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 40,
            imageUrl: img1952807372,
            providerInteriorImage: img1952807372,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [34],
            linkUrl: 'rafik-shereen',
            publicationsAndResearch: [],
            providerName: "Rafik A. Shereen",
            providerTitle: "MD",
            npi: 1952807372,
            specialty: ["Internal Medicine"],
            phone: "1-844-958-4189",
            languages: ["English", "Spanish", "Arabic"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Rafik Shereen, MD, practices internal medicine. Dr. Shereen earned his medical degree from St. George's University School of Medicine in Grenada. He completed his internship and residency at Arrowhead Regional Medical Center in California. Dr. Shereen strives to collaborate directly with his patients to create a treatment plan that best serves them. Dr. Shereen strongly believes in tailoring each treatment plan to a patient's needs and goals. His mission is to provide evidence-based care in a way that is approachable, empathetic and friendly. His clinical interests include geriatric health, heart health and the treatment of diabetes and obesity. Dr. Shereen prides himself on being a problem solver who always has each patient's best interest in mind. He's happy to help his patients navigate complex health care systems so they can experience benefits sooner. When he's not caring for his patients, Dr. Shereen loves to enjoy nature and spend time outdoors. He goes hiking and surfing as often as he can.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'St. George\'s University School of Medicine in Grenada'
                },
                {
                    type: 'Residency',
                    name: 'Arrowhead Regional Medical Center in California'
                },
                {
                    type: 'Internship',
                    name: 'Arrowhead Regional Medical Center in California'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Manhattan Beach",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 41,
            imageUrl: img1780620021,
            providerInteriorImage: img1780620021,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [35],
            linkUrl: 'john-d-ro',
            publicationsAndResearch: [],
            providerName: "John Daerin Ro",
            providerTitle: "MD",
            npi: 1780620021,
            specialty: ["Family Medicine"],
            phone: "1-844-958-4189",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "John Ro, MD, is board certified in family medicine. Dr. Ro earned his medical degree from Loma Linda University School of Medicine in California. He completed his internship and residency at Glendale Adventist Medical Center. Dr. Ro's patients say he is friendly and easy to talk to. He emphasizes preventive care and encourages his patients to make healthy lifestyle choices in order to achieve optimal health. In his spare time, Dr. Ro enjoys running, biking, snow sports, and photography, as well as spending time with his family.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Loma Linda University Medicine Center: Glendale Adventist Medical Center'
                },
                {
                    type: 'Residency',
                    name: 'Adventist Health Glendale'
                },
                {
                    type: 'Internship',
                    name: 'Adventist Health Glendale'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum -Torrance",
            location2: " ",
            region: ["South Bay"]
        },
        {
            id: 42,
            imageUrl: img1447348628,
            providerInteriorImage: img1447348628,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [37],
            linkUrl: 'steven-j-becker',
            publicationsAndResearch: [],
            providerName: "Steven Joseph Becker",
            providerTitle: "DO",
            npi: 1447348628,
            specialty: ["Internal Medicine"],
            phone: "1-866-324-0903",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Steven J. Becker, D.O. has a wide range of experience in internal medicine, from preventive care to management of chronic conditions for adults of all ages. He says, I see my role as a facilitator to get patients interested in an ongoing discussion about their health. I want them to feel comfortable making choices and having control over their medical decisions. Dr. Becker has been practicing medicine locally since 2000 and opened his private practice in 2005. Dr. Becker enjoys reading, cycling and spending his leisure time with his beautiful wife and two young daughters.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ohio University College of Osteopathic Medicine'
                },
                {
                    type: 'Residency',
                    name: 'College Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'College Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Seal Beach Village",
            location2: " ",
            region: ["North OC"]
        },
        {
            id: 43,
            imageUrl: img1568682979,
            providerInteriorImage: img1568682979,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [38],
            linkUrl: 'steven-l-smith',
            publicationsAndResearch: [],
            providerName: "Steven Lawrence Smith",
            providerTitle: "MD",
            npi: 1568682979,
            specialty: ["Family Medicine"],
            phone: "1-866-324-0903",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Steven Smith, MD, is board certified in family medicine. Dr. Smith earned his medical degree from Creighton University Medical Center in Nebraska. He completed his internship and his residency at the University of Colorado. Dr. Smith aims to put his patients first and practices evidence-based and individualized care so that each patient can lead a happy and healthy life. Dr. Smith strongly believes in seeing the patient-doctor relationship as a partnership. His goal is to educate the patient to help them meet their health goals together. Dr. Smith makes sure he offers patients all the tools they need to make informed and smart decisions about their care. A few of Dr. Smith's clinical interests include diabetes, geriatric care, and heart health. When he's not caring for his patients, Dr. Smith loves to spend time outdoors and with family. He enjoys hiking, biking and maintains a garden. He's an avid reader and a guitar player.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Creighton University Medical Center'
                },
                {
                    type: 'Residency',
                    name: 'University of Colorado'
                },
                {
                    type: 'Internship',
                    name: 'University of Colorado'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Los Alamitos",
            location2: " ",
            region: ["North OC"]
        },
        {
            id: 44,
            imageUrl: img1467457101,
            providerInteriorImage: img1467457101,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [39],
            linkUrl: 'victor-caluya',
            publicationsAndResearch: [],
            providerName: "Victor C. Caluya",
            providerTitle: "MD",
            npi: 1467457101,
            specialty: ["Internal Medicine"],
            phone: "1-866-324-0903",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Victor Caluya, MD, is board certified in internal medicine. Dr. Caluya earned his medical degree from Baylor College of Medicine in Houston. He completed his internship and residency at UC Irvine Medical Center. Dr. Caluya's patients say he is a good listener and spends extra time with him when needed. In his spare time, he enjoys playing the piano, computers, racquet sports, hiking and spending time with his family.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Baylor College of Medicine'
                },
                {
                    type: 'Residency',
                    name: 'UCI Medical Center'
                },
                {
                    type: 'Internship',
                    name: 'UCI Medical Center'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Buena Park",
            location2: " ",
            region: ["North OC"]
        },
        {
            id: 45,
            imageUrl: img1841677390,
            providerInteriorImage: img1841677390,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [41],
            linkUrl: 'kelly-gee',
            publicationsAndResearch: [],
            providerName: "Kelly Gee",
            providerTitle: "DO",
            npi: 1841677390,
            specialty: ["Family Medicine"],
            phone: "1-844-939-3730",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Touro University Nevada College of Osteopathic Medicine'
                },
                {
                    type: 'Residency',
                    name: 'University of Nebraska'
                },
                {
                    type: 'Internship',
                    name: 'University of Nebraska'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            school: "",
            internship: "Still OPTI/Wright Ctr National Family Med-HealthSource",
            residency: "Still OPTI/Wright Ctr National Family Med-HealthSource",
            digital: "Yes",
            location: "Optum - Laguna Hills",
            location2: " ",
            region: ["South OC"]
        },
        {
            id: 46,
            imageUrl: img1710932751,
            providerInteriorImage: img1710932751,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [42],
            linkUrl: 'bakul-patel',
            publicationsAndResearch: [],
            providerName: "Bakul Patel",
            providerTitle: "MD",
            npi: 1710932751,
            specialty: ["Internal Medicine"],
            phone: "1-844-939-3730",
            languages: ["English", "Gujarati", "Hindi"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Bakul Patel, MD, practices internal medicine. Dr. Patel earned his medical degree from the Welsh National School of Medicine in Cardiff, Wales. He completed his internship and residency at the University of Nebraska. Dr. Patel is a good listener. He works to empower his patients to make educated decisions regarding their health. Dr. Patel is passionate about helping his patients prevent and manage chronic conditions, including cardiovascular disease, diabetes, asthma, depression, anxiety, osteoporosis, dementia and sleeping disorders. He carefully reviews each patient's use of prescription drugs, over-the-counter medications and herbal supplements. Dr. Patel speaks English, Gujarati, and Hindi.\r\n",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Welsh National School'
                },
                {
                    type: 'Residency',
                    name: 'University of Nebraska'
                },
                {
                    type: 'Internship',
                    name: 'University of Nebraska'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Foothill Ranch",
            location2: " ",
            region: ["South OC"]
        },
        {
            id: 47,
            imageUrl: img1326466285,
            providerInteriorImage: img1326466285,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [43],
            linkUrl: 'tracy-pantig',
            publicationsAndResearch: [],
            providerName: "Tracy Pantig",
            providerTitle: "MD",
            npi: 1326466285,
            specialty: ["Family Medicine"],
            phone: "1-844-939-3730",
            languages: ["English"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: ' '
                },
                {
                    type: 'Residency',
                    name: ' '
                },
                {
                    type: 'Internship',
                    name: ' '
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - San Clemente",
            location2: " ",
            region: ["South OC"]
        },
        {
            id: 48,
            imageUrl: img1619521556,
            providerInteriorImage: img1619521556,
            sex: "Female",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [44],
            linkUrl: 'mahsa-safavi',
            publicationsAndResearch: [],
            providerName: "Mahsa Safavi",
            providerTitle: "MD",
            npi: 1619521556,
            specialty: ["Family Medicine"],
            phone: "1-844-939-3730",
            languages: ["English", "Persian/Farsi"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Tehran University of Medical Sciences'
                },
                {
                    type: 'Residency',
                    name: 'University of Toronto'
                },
                {
                    type: 'Internship',
                    name: ' '
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Rancho Santa Margarita",
            location2: " ",
            region: ["South OC"]
        },
        {
            id: 49,
            imageUrl: img1841432507,
            providerInteriorImage: img1841432507,
            sex: "Male",
            providerPhilosophy: [],
            organizations: [],
            professionalAssociations: [],
            providerLocations: [48],
            linkUrl: 'allen-jahroumi',
            publicationsAndResearch: [],
            providerName: "Allen Jahroumi",
            providerTitle: "MD",
            npi: 1841432507,
            specialty: ["Family Medicine"],
            phone: "1-844-939-3730",
            languages: ["English", "Persian/Farsi"],
            telehealth: "Yes",
            panel: "Accepting new patients",
            affiliation: "Optum",
            status: "Employed",
            providerBio: "Provider to fill out link",
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'St Georges University'
                },
                {
                    type: 'Residency',
                    name: 'Stony Brook University'
                },
                {
                    type: 'Internship',
                    name: 'Stony Brook University'
                },
                {
                    type: "Fellowship",
                    name: ""
                }
            ],
            digital: "Yes",
            location: "Optum - Mission Viejo",
            location2: " ",
            region: ["South OC"]
        },
    ],


    buttons: [
        {
            id: 1,
            buttonCopy: "San Gabriel Valley",
            buttonLink: "#san-gabriel-valley",
        },
        {
            id: 2,
            buttonCopy: "San Fernando Valley",
            buttonLink: "#san-fernando-valley"
        },
        {
            id: 3,
            buttonCopy: "South Bay",
            buttonLink: "#south-bay"
        },
        {
            id: 4,
            buttonCopy: "LA/Downtown",
            buttonLink: "#la-downtown"
        },
        {
            id: 5,
            buttonCopy: "South OC",
            buttonLink: "#south-oc"
        },
        {
            id: 6,
            buttonCopy: "North OC",
            buttonLink: "#north-oc"
        },
        {
            id: 7,
            buttonCopy: "Long Beach",
            buttonLink: "#long-beach"
        },*/
    ]
}

export default PROVIDERS_DATA;

