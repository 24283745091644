import { combineReducers } from 'redux';

import providerReducer from './provider/provider.reducer';

// const rootReducer = ({
//     provider: providerReducer,
//     article: articleReducer
// })

export default combineReducers({
    provider: providerReducer,
})
