import React from 'react';
import TOOLKIT_DATA from "./patient-resources.data";
import {Helmet} from "react-helmet";
import HeaderHero from "../../components/header-hero/header-hero.component";
import CardsRow from "../../components/cards-row/cards-row.component";
import InfoCardsRow from "../../components/info-cards-row/info-cards-row.component";
import HighlightsWithTitle from "../../components/highlight-with-title/highlights-with-title.component";
import FAQRowFull from "../../components/faq-row/faq-row-full.component";
import FAQRow from "../../components/faq-row/faq-row.component";
import FaqDropdown from "../../components/faq-dropdown/faq-dropdown-row.component";
import UCLocationsTable from "../../components/locations-table/urgent-care/locations-table.component";
import ERLocationsTable from "../../components/locations-table/emergency-rooms/locations-table.component";
import TextFullRow from "../../components/text-full-row/text-row-full.component";
import TextFullRow2 from "../../components/text-full-row-2/text-row-full.component";
import PATIENT_RESOURCES_DATA from "./patient-resources.data";
import BreadcrumbTop from "../../components/breadcrumb-top/breadcrumb-top.component";

class PatientResourcesPage extends React.Component {
    state = PATIENT_RESOURCES_DATA;
    render() {
        const {page, hero, cardsRow1, cardsRow2, faqData, faqData2, breadcrumb} = this.state;
        return(
            <>
                <main className={"page-body"}>
                    <Helmet>
                        <title>{page.title}</title>
                    </Helmet>

                    <div className={"container-fluid"}>
                        <BreadcrumbTop info={breadcrumb}/>
                        <HeaderHero info={hero} />
                        {/* <CardsRow info={cardsRow1}/>*/}
                        <FaqDropdown info={faqData}/>
                        <TextFullRow2 />
                        <UCLocationsTable />
                        <ERLocationsTable />
                        <TextFullRow />
                    </div>
                </main>
            </>
        );
    }
}

export default PatientResourcesPage;
