import React from 'react';
import locationsData from "../../../pages/locations/locations.data";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

var locations = locationsData.sections;

const ProviderThumbnail = ({ title, id, npi, providerLocations, providerSpecialty, location, languages, imageUrl, specialty, linkUrl, providerName, providerTitle, history, match }) => (
    <div className="col-lg-3 col-md-4 col-6 mb-1 mb-lg-4 h-100" onClick={ () => history.push(`${match.url}${linkUrl}`) }>
        <a href={`/providers/${linkUrl}`}>
            <div className="card h-100">
                <img src={imageUrl}
                     className="card-img-top"
                     alt={`Provider ${providerName}'s profile image`}/>
                <div className="card-body" style={{minHeight: "170px"}}>
                    <h6 className="font-body-1 font-navy-blue"><b>{providerName}, {providerTitle}</b></h6>
                    <h6 className={"font-body-3"}>{providerSpecialty.length > 1 ? (providerSpecialty.map(spec => spec+" ")) : (providerSpecialty.map(spec => spec)) }{" |" + languages.map(lang => " "+lang)}</h6>

                    <h6 className={"font-body-3"}>
                        { locations.find(loc => loc.id === providerLocations[0]).address1 }
                    </h6>
                    <a href={`/providers/${linkUrl}`}>
                        <span className={"font-body-3 font-blue"}>
                            <b>Meet Dr. {providerName.split(" ").pop()} <FontAwesomeIcon icon={faChevronRight}/></b>
                        </span>
                    </a>
                </div>
            </div>
        </a>
    </div>
)

export default ProviderThumbnail;
